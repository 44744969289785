const WineList = ( {choiche, wines, selectedWine, setSelectedWine} ) => {

    function handleWineChange(id) {
        if (selectedWine === id) {
            setSelectedWine(-1)
            return
        }
        setSelectedWine(id)
    }

    return (
        <div className="wine-list">
            {
                choiche == 2 && wines.length > 0 && wines.slice().sort((a, b) => a.price - b.price).map(wine => (
                    <div className={ selectedWine === wine['_id'] ? 'wine wine-selected' : 'wine wine-unselected' } key={wine['_id']} onClick={() => handleWineChange(wine['_id']) } >
                        <h1>{wine.name}</h1>
                        <div className="wine-info">
                            <div className="wine-info-top">
                                <p><b>REGIONE: </b>{wine.region}</p>
                                <p><b>PREZZO: </b>{wine.price}</p>
                            </div>
                            <p>{wine.description}</p>
                        </div>
                    </div>
                ))
            }
            { choiche == 2 && wines.length == 0 ?
                <h1>Nessun Vino trovato</h1> : ''
            }
        </div>
    )
}

export default WineList