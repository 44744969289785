const FeatureByCategory = {
    "Bianco": [
        "Complesso",
        "Intenso",
        "Minerale",
        "Speziato",
        "Persistente",
        "Armonico",
        "Strutturato",
        "Dolce",
        "Leggero",
        "Avvolgente",
        "Elegante",
        "Agrumato",
        "Floreale",
        "Robusto",
        "Aromatico",
        "Fruttato",
        "Vivace",
        "Fresco"
    ],
    "Rosso": [
        "Speziato",
        "Strutturato",
        "Potente",
        "Intenso",
        "Elegante",
        "Fruttato",
        "Vivace",
        "Complesso",
        "Minerale",
        "Leggero",
        "Avvolgente",
        "Agrumato",
        "Floreale",
        "Persistente",
        "Armonico",
        "Dolce",
        "Robusto",
        "Aromatico",
        "Fresco"
    ],
    "Rosato": [
        "Complesso",
        "Intenso",
        "Armonico",
        "Elegante",
        "Floreale",
        "Robusto",
        "Fruttato",
        "Fresco"
    ],
    "Vino Estero": [
        "Complesso",
        "Intenso",
        "Minerale",
        "Dolce",
        "Elegante",
        "Floreale",
        "Robusto",
        "Fruttato",
        "Persistente",
        "Fresco"
    ],
    "Vino Liquoroso": [
        "Intenso",
        "Complesso",
        "Dolce",
        "Fruttato",
        "Persistente"
    ]
}

export default FeatureByCategory