const allWines = [
    {
        "_id": "112480",
        "name": "Capichera Vermentino Gallura DOCG 75 cl",
        "category": "Bianco",
        "region": "Sardegna",
        "description": "Un elegante vino bianco italiano con note fruttate e floreali.",
        "price": "34.90",
        "features": [
            "Fruttato",
            "Elegante"
        ]
    },
    {
        "_id": "112488",
        "name": "Ceretto Langhe Arneis DOC Bianco 75 cl",
        "category": "Bianco",
        "region": "Piemonte",
        "description": "Un vino bianco italiano fresco e leggero con sentori fruttati.",
        "price": "19.90",
        "features": [
            "Fruttato",
            "Leggero"
        ]
    },
    {
        "_id": "112491",
        "name": "Elena Walch Gewürztraminer DOC 75 cl",
        "category": "Bianco",
        "region": "Trentino",
        "description": "Un vino bianco italiano aromatico con un bouquet floreale unico.",
        "price": "18.90",
        "features": [
            "Floreale",
            "Aromatico"
        ]
    },
    {
        "_id": "125591",
        "name": "Clelia Fiano Avellino DOCG 75 cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Un vino bianco italiano con caratteristiche fruttate e minerali.",
        "price": "15.90",
        "features": [
            "Fruttato",
            "Minerale"
        ]
    },
    {
        "_id": "125592",
        "name": "Devon Greco di Tufo DOCG 75 cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Un vino bianco italiano dal carattere distintivo e complesso.",
        "price": "15.90",
        "features": [
            "Complesso",
            "Elegante"
        ]
    },
    {
        "_id": "125595",
        "name": "Livio Felluga Sauvignon Blanc 75 cl",
        "category": "Bianco",
        "region": "Friuli-Venezia Giulia",
        "description": "Un vino bianco italiano fresco con aromi erbacei e agrumati.",
        "price": "23.90",
        "features": [
            "Aromatico",
            "Agrumato"
        ]
    },
    {
        "_id": "125597",
        "name": "Livio Felluga Chardonnay DOC 75 cl",
        "category": "Bianco",
        "region": "Friuli-Venezia Giulia",
        "description": "Un vino bianco italiano con sentori di frutta matura e leggere note di legno.",
        "price": "23.90",
        "features": [
            "Fruttato",
            "Complesso"
        ]
    },
    {
        "_id": "127262",
        "name": "Villa Raiano Greco di Tufo 75 cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Un vino bianco italiano dalla mineralità distintiva.",
        "price": "19.90",
        "features": [
            "Minerale",
            "Elegante"
        ]
    },
    {
        "_id": "127263",
        "name": "St. Valentin Gewürztraminer 75 cl",
        "category": "Bianco",
        "region": "Trentino",
        "description": "Un vino bianco italiano con un bouquet aromatico intenso e speziato.",
        "price": "33.90",
        "features": [
            "Intenso",
            "Speziato"
        ]
    },
    {
        "_id": "127264",
        "name": "St. Valentin Sauvignon Trentino 75 cl",
        "category": "Bianco",
        "region": "Trentino",
        "description": "Un vino bianco italiano fresco con sentori erbacei e agrumati.",
        "price": "33.90",
        "features": [
            "Aromatico",
            "Agrumato"
        ]
    },
    {
        "_id": "127288",
        "name": "Prepositus Riesling 75 cl",
        "category": "Bianco",
        "region": "Trentino",
        "description": "Un vino bianco italiano fresco e fruttato con un tocco minerale.",
        "price": "26.90",
        "features": [
            "Fruttato",
            "Minerale"
        ]
    },
    {
        "_id": "127289",
        "name": "Romano Clelia Colli di Lapio DOCG 75 cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Un vino bianco italiano con un carattere vivace e floreale.",
        "price": "16.90",
        "features": [
            "Vivace",
            "Floreale"
        ]
    },
    {
        "_id": "127290",
        "name": "Colli di Lapio Greco di Tufo DOP 75 cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Un vino bianco italiano con note minerali e fruttate.",
        "price": "15.90",
        "features": [
            "Minerale",
            "Fruttato"
        ]
    },
    {
        "_id": "127303",
        "name": "Vie de Romans Chardonnay 75 cl",
        "category": "Bianco",
        "region": "Friuli-Venezia Giulia",
        "description": "Un raffinato vino bianco italiano con note di burro e vaniglia.",
        "price": "22.90",
        "features": [
            "Elegante",
            "Complesso"
        ]
    },
    {
        "_id": "129179",
        "name": "San Michele Appiano Sauvignon Lahn 75 cl",
        "category": "Bianco",
        "region": "Trentino",
        "description": "Un vino bianco italiano fresco e fruttato con un tocco erbaceo.",
        "price": "17.90",
        "features": [
            "Fruttato",
            "Aromatico"
        ]
    },
    {
        "_id": "129180",
        "name": "San Michele Appiano Pinot Grigio Anger 75 cl",
        "category": "Bianco",
        "region": "Trentino",
        "description": "Un vino bianco italiano con sentori fruttati e minerali.",
        "price": "16.90",
        "features": [
            "Fruttato",
            "Minerale"
        ]
    },
    {
        "_id": "129181",
        "name": "San Michele Appiano Riesling Montiggl 75 cl",
        "category": "Bianco",
        "region": "Trentino",
        "description": "Un vino bianco italiano con una freschezza e acidità vibrante.",
        "price": "18.90",
        "features": [
            "Fresco",
            "Agrumato"
        ]
    },
    {
        "_id": "129194",
        "name": "Hofstätter Michel Sauvignon 75 cl",
        "category": "Bianco",
        "region": "Trentino",
        "description": "Un vino bianco italiano con note agrumate e un tocco erbaceo.",
        "price": "17.50",
        "features": [
            "Agrumato",
            "Aromatico"
        ]
    },
    {
        "_id": "129195",
        "name": "Hofstätter Joseph Gewürztraminer 75 cl",
        "category": "Bianco",
        "region": "Trentino",
        "description": "Un vino bianco italiano con un bouquet aromatico unico.",
        "price": "18.50",
        "features": [
            "Aromatico",
            "Speziato"
        ]
    },
    {
        "_id": "129220",
        "name": "Hofstätter De Vite Sauvignon 75 cl",
        "category": "Bianco",
        "region": "Trentino",
        "description": "Un vino bianco italiano con un carattere fresco e fruttato.",
        "price": "15.40",
        "features": [
            "Fresco",
            "Fruttato"
        ]
    },
    {
        "_id": "129235",
        "name": "Cantina d'Ambra Frassitelli DOC 75 cl",
        "category": "Bianco",
        "region": "Sicilia",
        "description": "Un vino bianco italiano con una mineralità distintiva e freschezza.",
        "price": "22.90",
        "features": [
            "Minerale",
            "Fresco"
        ]
    },
    {
        "_id": "135646",
        "name": "Villa Sandi Ribolla Gialla 75 cl",
        "category": "Bianco",
        "region": "Veneto",
        "description": "Un vino bianco italiano con note fruttate e un retrogusto fresco.",
        "price": "17.90",
        "features": [
            "Fruttato",
            "Fresco"
        ]
    },
    {
        "_id": "136446",
        "name": "Mastroberardino Confalonieri Falanghina 75 cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Un vino bianco italiano con caratteristiche fruttate e minerali.",
        "price": "16.90",
        "features": [
            "Fruttato",
            "Minerale"
        ]
    },
    {
        "_id": "138188",
        "name": "Picariello Ciro Fiano Avellino 75 cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Un vino bianco italiano con un carattere floreale e fruttato.",
        "price": "16.90",
        "features": [
            "Floreale",
            "Fruttato"
        ]
    },
    {
        "_id": "138191",
        "name": "Quintodecimo Fiano Avellino DOCG 75 cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Un vino bianco italiano di alta qualità con note complesse e minerali.",
        "price": "43.50",
        "features": [
            "Complesso",
            "Minerale"
        ]
    },
    {
        "_id": "138192",
        "name": "Quintodecimo Greco di Tufo DOCG 75 cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Un vino bianco italiano di alta qualità con un carattere distintivo.",
        "price": "44.00",
        "features": [
            "Elegante",
            "Complesso"
        ]
    },
    {
        "_id": "138200",
        "name": "Quintodecimo Falanghina 75 cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Un vino bianco italiano con un bouquet fruttato e floreale.",
        "price": "44.00",
        "features": [
            "Fruttato",
            "Floreale"
        ]
    },
    {
        "_id": "141148",
        "name": "Abbazia di Novacella Gewürztraminer 75 cl",
        "category": "Bianco",
        "region": "Trentino",
        "description": "Un vino bianco italiano aromatico con un bouquet ricco e speziato.",
        "price": "16.90",
        "features": [
            "Speziato",
            "Aromatico"
        ]
    },
    {
        "_id": "141164",
        "name": "Benito Ferrara Vigna Cicogna DOCG 75 cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Un vino bianco italiano di alta qualità con caratteristiche fruttate e minerali.",
        "price": "20.90",
        "features": [
            "Fruttato",
            "Minerale"
        ]
    },
    {
        "_id": "141171",
        "name": "Abbazia di Novacella Pinot Grigio 75 cl",
        "category": "Bianco",
        "region": "Trentino",
        "description": "Un vino bianco italiano con note fruttate e freschezza.",
        "price": "15.40",
        "features": [
            "Fruttato",
            "Fresco"
        ]
    },
    {
        "_id": "141183",
        "name": "Abbazia di Novacella Sauvignon Blanc 75 cl",
        "category": "Bianco",
        "region": "Trentino",
        "description": "Un vino bianco italiano con sentori erbacei e agrumati.",
        "price": "15.90",
        "features": [
            "Aromatico",
            "Agrumato"
        ]
    },
    {
        "_id": "141186",
        "name": "Kerner Abbazia di Novacella 75 cl",
        "category": "Bianco",
        "region": "Trentino",
        "description": "Un vino bianco italiano con caratteristiche fruttate e floreali.",
        "price": "15.90",
        "features": [
            "Fruttato",
            "Floreale"
        ]
    },
    {
        "_id": "141499",
        "name": "Gavi dei Gavi La Scolca 75 cl",
        "category": "Bianco",
        "region": "Piemonte",
        "description": "Un vino bianco italiano fresco con note di frutta a polpa bianca.",
        "price": "27.90",
        "features": [
            "Fruttato",
            "Fresco"
        ]
    },
    {
        "_id": "141518",
        "name": "Marsella Fiano Avellino 75 cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Un vino bianco italiano con note fruttate e minerali.",
        "price": "24.90",
        "features": [
            "Fruttato",
            "Minerale"
        ]
    },
    {
        "_id": "141519",
        "name": "Marisa Cuomo Fiorduva 75 cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Un vino bianco italiano di alta qualità con note complesse e minerali.",
        "price": "69.00",
        "features": [
            "Complesso",
            "Minerale"
        ]
    },
    {
        "_id": "141526",
        "name": "Ronchi Verdicchio Pieno 75 cl",
        "category": "Bianco",
        "region": "Marche",
        "description": "Un vino bianco italiano con sentori fruttati e una piacevole acidità.",
        "price": "18.40",
        "features": [
            "Fruttato",
            "Agrumato"
        ]
    },
    {
        "_id": "141527",
        "name": "Benito Ferrara Sequenzha DOCG 75 cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Un vino bianco italiano di alta qualità con sentori fruttati e minerali.",
        "price": "18.90",
        "features": [
            "Fruttato",
            "Minerale"
        ]
    },
    {
        "_id": "141529",
        "name": "Les Crêtes Cuvée Bois DOC 75 cl",
        "category": "Bianco",
        "region": "Valle d'Aosta",
        "description": "Un vino bianco italiano di alta qualità con caratteristiche complesse e leggere note di legno.",
        "price": "50.90",
        "features": [
            "Elegante",
            "Complesso"
        ]
    },
    {
        "_id": "141537",
        "name": "Ceretto Blange 150 cl",
        "category": "Bianco",
        "region": "Piemonte",
        "description": "Un vino bianco italiano fresco e fruttato con note di frutta esotica.",
        "price": "49.00",
        "features": [
            "Elegante",
            "Fruttato"
        ]
    },
    {
        "_id": "141544",
        "name": "Terlan Terlaner Classico 75 cl",
        "category": "Bianco",
        "region": "Trentino",
        "description": "Un vino bianco italiano con un carattere fresco e fruttato.",
        "price": "18.90",
        "features": [
            "Fresco",
            "Fruttato"
        ]
    },
    {
        "_id": "142558",
        "name": "Maffini Pietraincatenata Dop Bio 75 cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Un vino bianco italiano biologico con note minerali e complessità.",
        "price": "35.40",
        "features": [
            "Minerale",
            "Complesso"
        ]
    },
    {
        "_id": "143037",
        "name": "Tasca d'Almerita Nozze d'Oro DOC 75 cl",
        "category": "Bianco",
        "region": "Sicilia",
        "description": "Un vino bianco italiano di alta qualità con note fruttate e minerali.",
        "price": "25.90",
        "features": [
            "Fruttato",
            "Minerale"
        ]
    },
    {
        "_id": "143041",
        "name": "Franz Haas Manna Val di Cembra Dolomiti 75 cl",
        "category": "Bianco",
        "region": "Trentino",
        "description": "Un vino bianco italiano con caratteristiche fruttate e freschezza.",
        "price": "29.90",
        "features": [
            "Fruttato",
            "Fresco"
        ]
    },
    {
        "_id": "143046",
        "name": "Livio Felluga Ribolla Gialla DOC 75 cl",
        "category": "Bianco",
        "region": "Friuli-Venezia Giulia",
        "description": "Un vino bianco italiano con sentori fruttati e una piacevole acidità.",
        "price": "23.90",
        "features": [
            "Fruttato",
            "Agrumato"
        ]
    },
    {
        "_id": "143050",
        "name": "Marina Cvetic Chardonnay IGT 75 cl",
        "category": "Bianco",
        "region": "Abruzzo",
        "description": "Un vino bianco italiano con un bouquet complesso e note di burro.",
        "price": "35.90",
        "features": [
            "Elegante",
            "Complesso"
        ]
    },
    {
        "_id": "143051",
        "name": "Planeta Chardonnay IGT 75 cl",
        "category": "Bianco",
        "region": "Sicilia",
        "description": "Un vino bianco italiano con note fruttate e una piacevole acidità.",
        "price": "28.40",
        "features": [
            "Fruttato",
            "Agrumato"
        ]
    },
    {
        "_id": "143052",
        "name": "St. Valentin Chardonnay DOC 75 cl",
        "category": "Bianco",
        "region": "Trentino",
        "description": "Un vino bianco italiano di alta qualità con caratteristiche complesse e leggere note di legno.",
        "price": "33.90",
        "features": [
            "Elegante",
            "Complesso"
        ]
    },
    {
        "_id": "143054",
        "name": "Benito Ferrara Terre di Uva DOCG 75 cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Un vino bianco italiano con note fruttate e una piacevole acidità.",
        "price": "17.90",
        "features": [
            "Fruttato",
            "Agrumato"
        ]
    },
    {
        "_id": "143056",
        "name": "Livio Felluga Pinot Grigio DOC 75 cl",
        "category": "Bianco",
        "region": "Friuli-Venezia Giulia",
        "description": "Un vino bianco italiano con note fruttate e minerali.",
        "price": "23.90",
        "features": [
            "Fruttato",
            "Minerale"
        ]
    },
    {
        "_id": "143060",
        "name": "Tasca d'Almerita Leone di Terre Siciliane 75 cl",
        "category": "Bianco",
        "region": "Sicilia",
        "description": "Un vino bianco italiano con note fruttate e una piacevole acidità.",
        "price": "15.40",
        "features": [
            "Fruttato",
            "Agrumato"
        ]
    },
    {
        "_id": "143064",
        "name": "Villa Diamante Fiano di Avellino DOCG 75 cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Un vino bianco italiano con note fruttate e minerali.",
        "price": "32.90",
        "features": [
            "Fruttato",
            "Minerale"
        ]
    },
    {
        "_id": "143065",
        "name": "Colterenzio Lafoa Gewürztraminer DOC 75 cl",
        "category": "Bianco",
        "region": "Trentino",
        "description": "Un vino bianco italiano aromatico con note di frutta esotica.",
        "price": "28.90",
        "features": [
            "Aromatico",
            "Fruttato"
        ]
    },
    {
        "_id": "143066",
        "name": "Elena Walch Chardonnay DOC 75 cl",
        "category": "Bianco",
        "region": "Trentino",
        "description": "Un vino bianco italiano con sentori fruttati e una piacevole acidità.",
        "price": "21.00",
        "features": [
            "Fruttato",
            "Agrumato"
        ]
    },
    {
        "_id": "143069",
        "name": "Franz Haas Gewürztraminer DOC 75 cl",
        "category": "Bianco",
        "region": "Trentino",
        "description": "Un vino bianco italiano aromatico con note di frutta esotica.",
        "price": "21.90",
        "features": [
            "Aromatico",
            "Fruttato"
        ]
    },
    {
        "_id": "143074",
        "name": "Terlan Winkl Sauvignon Blanc DOC 75 cl",
        "category": "Bianco",
        "region": "Trentino",
        "description": "Un vino bianco italiano con sentori erbacei e una piacevole acidità.",
        "price": "22.40",
        "features": [
            "Aromatico",
            "Agrumato"
        ]
    },
    {
        "_id": "143075",
        "name": "Terlan Vorberg Pinot Bianco DOC 75 cl",
        "category": "Bianco",
        "region": "Trentino",
        "description": "Un vino bianco italiano con note fruttate e una piacevole acidità.",
        "price": "33.40",
        "features": [
            "Fruttato",
            "Agrumato"
        ]
    },
    {
        "_id": "143076",
        "name": "Terlan Gewürztraminer DOC 75 cl",
        "category": "Bianco",
        "region": "Trentino",
        "description": "Un vino bianco italiano aromatico con note di frutta esotica.",
        "price": "17.40",
        "features": [
            "Aromatico",
            "Fruttato"
        ]
    },
    {
        "_id": "143079",
        "name": "Colli di Lapio Greco di Tufo DOCG 75 cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Un vino bianco italiano con caratteristiche fruttate e minerali.",
        "price": "17.40",
        "features": [
            "Fruttato",
            "Minerale"
        ]
    },
    {
        "_id": "143082",
        "name": "Tenuta San Francesco 'X Eva' Costa d'Amalfi Furore Bianco IGP 75 cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Un vino bianco italiano con note fruttate e una piacevole acidità.",
        "price": "29.40",
        "features": [
            "Fruttato",
            "Agrumato"
        ]
    },
    {
        "_id": "143086",
        "name": "Tiare Collio Sauvignon DOC 75 cl",
        "category": "Bianco",
        "region": "Friuli-Venezia Giulia",
        "description": "Un vino bianco italiano con sentori erbacei e una piacevole acidità.",
        "price": "18.90",
        "features": [
            "Aromatico",
            "Agrumato"
        ]
    },
    {
        "_id": "143089",
        "name": "Scuotto Oini Fiano IGP 75 cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Un vino bianco italiano con note fruttate e una piacevole acidità.",
        "price": "27.90",
        "features": [
            "Fruttato",
            "Agrumato"
        ]
    },
    {
        "_id": "143098",
        "name": "Cenatiempo Ischia DOC 75 cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Un vino bianco italiano con note fruttate e minerali.",
        "price": "19.90",
        "features": [
            "Fruttato",
            "Minerale"
        ]
    },
    {
        "_id": "143102",
        "name": "Cenatiempo Kalimera DOC 75 cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Un vino bianco italiano con note fruttate e minerali.",
        "price": "27.90",
        "features": [
            "Fruttato",
            "Minerale"
        ]
    },
    {
        "_id": "147668",
        "name": "J. Hofstätter Gewürztraminer Kolbenhof DOC 75 cl",
        "category": "Bianco",
        "region": "Trentino",
        "description": "Un vino bianco italiano aromatico con note di frutta esotica.",
        "price": "45.40",
        "features": [
            "Aromatico",
            "Fruttato"
        ]
    },
    {
        "_id": "152212",
        "name": "Marisa Cuomo Costa d'Amalfi Bianco DOC 75 cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Un vino bianco italiano con note fruttate e una piacevole acidità.",
        "price": "19.90",
        "features": [
            "Fruttato",
            "Agrumato"
        ]
    },
    {
        "_id": "154187",
        "name": "Ceretto Blange DOC 75 cl 2022",
        "category": "Bianco",
        "region": "Piemonte",
        "description": "Un vino bianco italiano fresco e aromatico con note fruttate.",
        "price": "19.90",
        "features": [
            "Fresco",
            "Aromatico"
        ]
    },
    {
        "_id": "156543",
        "name": "Marisa Cuomo Ravello Bianco DOC 75 cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Un vino bianco italiano con note fruttate e minerali.",
        "price": "29.90",
        "features": [
            "Fruttato",
            "Minerale"
        ]
    },
    {
        "_id": "156545",
        "name": "Marisa Cuomo Furore Bianco DOC 75 cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Un vino bianco italiano con caratteristiche fruttate e minerali.",
        "price": "29.90",
        "features": [
            "Fruttato",
            "Minerale"
        ]
    },
    {
        "_id": "157588",
        "name": "Pepe Grancare Magnum 1,5L",
        "category": "Bianco",
        "region": "Campania",
        "description": "Una bottiglia magnum di un vino bianco italiano di alta qualità.",
        "price": "44.90",
        "features": [
            "Intenso",
            "Elegante"
        ]
    },
    {
        "_id": "157599",
        "name": "Mastroberardino Magnum Redimore Fiano Avellino 150 cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Un vino bianco italiano con note fruttate e complessità aromatica.",
        "price": "39.90",
        "features": [
            "Fruttato",
            "Complesso"
        ]
    },
    {
        "_id": "157600",
        "name": "Mastroberardino Cofanetto Duets Morabianco",
        "category": "Bianco",
        "region": "Campania",
        "description": "Un elegante vino bianco italiano con note fruttate e floreali.",
        "price": "25.90",
        "features": [
            "Fruttato",
            "Floreale"
        ]
    },
    {
        "_id": "157653",
        "name": "Villa Matilde Confettura Duemiladodici 2X75 cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Un vino bianco italiano con note fruttate e minerali.",
        "price": "24.90",
        "features": [
            "Fruttato",
            "Minerale"
        ]
    },
    {
        "_id": "192105",
        "name": "Mastroberardino Stilema Fiano Avellino 75 cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Un vino bianco italiano di alta qualità con complessità aromatica.",
        "price": "44.90",
        "features": [
            "Complesso",
            "Elegante"
        ]
    },
    {
        "_id": "197236",
        "name": "Feudi San Gregorio Privilegio Fiano di Avellino Passito DOCG 50 cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Un vino italiano dolce e ricco con note fruttate e floreali.",
        "price": "23.90",
        "features": [
            "Fruttato",
            "Floreale"
        ]
    },
    {
        "_id": "351734",
        "name": "Mastroberardino More Maiorum Fiano di Avellino 75 cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Un vino bianco italiano fresco e aromatico con note fruttate.",
        "price": "19.00",
        "features": [
            "Fresco",
            "Aromatico"
        ]
    },
    {
        "_id": "434969",
        "name": "Feudi San Gregorio Greco di Tufo DOCG Magnum 1,5L",
        "category": "Bianco",
        "region": "Campania",
        "description": "Un vino italiano di alta qualità in formato Magnum con note fruttate e minerali.",
        "price": "28.90",
        "features": [
            "Intenso",
            "Minerale"
        ]
    },
    {
        "_id": "434977",
        "name": "Feudi San Gregorio Fiano di Avellino Magnum 1,5L",
        "category": "Bianco",
        "region": "Campania",
        "description": "Un vino italiano in formato Magnum con note fruttate e una piacevole acidità.",
        "price": "19.90",
        "features": [
            "Intenso",
            "Agrumato"
        ]
    },
    {
        "_id": "572719",
        "name": "Feudi San Gregorio Campanaro IGT 75 cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Un vino bianco italiano con note fruttate e una piacevole acidità.",
        "price": "19.90",
        "features": [
            "Fruttato",
            "Agrumato"
        ]
    },
    {
        "_id": "766501",
        "name": "Feudi San Gregorio Falanghina del Sannio Magnum 1,5L",
        "category": "Bianco",
        "region": "Campania",
        "description": "Un vino italiano in formato Magnum con note fruttate e una piacevole acidità.",
        "price": "19.90",
        "features": [
            "Intenso",
            "Agrumato"
        ]
    },
    {
        "_id": "772257",
        "name": "Volpe Pasini Ribolla Gialla 75 cl",
        "category": "Bianco",
        "region": "Friuli-Venezia Giulia",
        "description": "Un vino bianco italiano con note fruttate e una piacevole acidità.",
        "price": "18.90",
        "features": [
            "Fruttato",
            "Agrumato"
        ]
    },
    {
        "_id": "774756",
        "name": "Joaquin Fiano Avellini DOCG 75 cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Vino bianco italiano con varietà di Fiano da Avellino.",
        "price": "47.90",
        "features": [
            "Elegante",
            "Floreale"
        ]
    },
    {
        "_id": "CS00174",
        "name": "Furore Costa d'Amalfi Bianco Cl. 75",
        "category": "Bianco",
        "region": "Campania",
        "description": "Vino bianco italiano della Costiera Amalfitana.",
        "price": "22.00",
        "features": [
            "Minerale",
            "Vivace"
        ]
    },
    {
        "_id": "CS01622",
        "name": "Prosecco Cuvee Millesimato DOCG Cl. 75",
        "category": "Bianco",
        "region": "Veneto",
        "description": "Vino bianco italiano frizzante e millesimato.",
        "price": "18.50",
        "features": [
            "Fresco",
            "Floreale"
        ]
    },
    {
        "_id": "CS01624",
        "name": "Prosecco Cartizze DOCG Cl. 75",
        "category": "Bianco",
        "region": "Veneto",
        "description": "Vino bianco italiano di alta qualità da Cartizze.",
        "price": "27.80",
        "features": [
            "Elegante",
            "Fruttato"
        ]
    },
    {
        "_id": "CS05305",
        "name": "Tolos Montepulciano LT",
        "category": "Rosso",
        "region": "Abruzzo",
        "description": "Vino rosso italiano con varietà di Montepulciano.",
        "price": "45.00",
        "features": [
            "Robusto",
            "Strutturato"
        ]
    },
    {
        "_id": "CS06762",
        "name": "Lama dei Corvi DOC Cl. 75",
        "category": "Bianco",
        "region": "Umbria",
        "description": "Vino bianco italiano con varietà di Lama dei Corvi.",
        "price": "16.00",
        "features": [
            "Floreale",
            "Armonico"
        ]
    },
    {
        "_id": "CS07860",
        "name": "Sauvignon Fallwind 2021 Cl. 75",
        "category": "Bianco",
        "region": "Friuli-Venezia Giulia",
        "description": "Vino bianco italiano con varietà di Sauvignon Blanc.",
        "price": "18.90",
        "features": [
            "Fresco",
            "Aromatico"
        ]
    },
    {
        "_id": "CS08003",
        "name": "Montevetrano Bianco 2021 Cl. 75",
        "category": "Bianco",
        "region": "Campania",
        "description": "Vino bianco italiano con varietà di Montevetrano.",
        "price": "18.50",
        "features": [
            "Elegante",
            "Floreale"
        ]
    },
    {
        "_id": "CS08450",
        "name": "Lunelli Villa Margon Cl. 75",
        "category": "Bianco",
        "region": "Trentino",
        "description": "Vino bianco italiano della Villa Margon.",
        "price": "31.90",
        "features": [
            "Complesso",
            "Elegante"
        ]
    },
    {
        "_id": "103508",
        "name": "Leonardo Brunello DOCG 75 cl",
        "category": "Rosso",
        "region": "Toscana",
        "description": "Vino rosso italiano di alta qualità da Brunello.",
        "price": "19.90",
        "features": [
            "Robusto",
            "Persistente"
        ]
    },
    {
        "_id": "109617",
        "name": "Fontanafredda Barolo DOCG Riserva 75 cl",
        "category": "Rosso",
        "region": "Piemonte",
        "description": "Vino rosso italiano di alta qualità da Barolo Riserva.",
        "price": "27.90",
        "features": [
            "Elegante",
            "Strutturato"
        ]
    },
    {
        "_id": "109618",
        "name": "Fontanafredda Barolo DOCG 150 cl",
        "category": "Rosso",
        "region": "Piemonte",
        "description": "Vino rosso italiano di alta qualità da Barolo.",
        "price": "49.50",
        "features": [
            "Robusto",
            "Elegante"
        ]
    },
    {
        "_id": "109620",
        "name": "Fontanafredda Barbaresco DOCG 75cl",
        "category": "Rosso",
        "region": "Piemonte",
        "description": "Elegante vino rosso prodotto nella regione del Piemonte.",
        "price": "20.90",
        "features": [
            "Complesso",
            "Leggero"
        ]
    },
    {
        "_id": "109622",
        "name": "Fontanafredda Barbera d'Alba DOC 150cl",
        "category": "Rosso",
        "region": "Piemonte",
        "description": "Vino rosso robusto con profilo fruttato.",
        "price": "17.90",
        "features": [
            "Fruttato",
            "Robusto"
        ]
    },
    {
        "_id": "109623",
        "name": "Fontanafredda Barbera d'Alba DOC 300cl",
        "category": "Rosso",
        "region": "Piemonte",
        "description": "Ampia bottiglia di Barbera d'Alba, ideale per occasioni speciali.",
        "price": "34.90",
        "features": [
            "Robusto",
            "Fruttato"
        ]
    },
    {
        "_id": "112460",
        "name": "Hofstätter Merlot Trentino DOC 75cl",
        "category": "Rosso",
        "region": "Trentino",
        "description": "Merlot elegante con carattere distintivo dell'Trentino.",
        "price": "15.90",
        "features": [
            "Armonico",
            "Fruttato"
        ]
    },
    {
        "_id": "112489",
        "name": "Elena Walch Pinot Nero 75cl",
        "category": "Rosso",
        "region": "Trentino",
        "description": "Vino rosso di alta qualità prodotto con uve Pinot Nero.",
        "price": "17.40",
        "features": [
            "Complesso",
            "Fruttato"
        ]
    },
    {
        "_id": "112529",
        "name": "Quintarelli Terre del Vescovo Aglianico Irpino DOC 75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Vino prestigioso con uve Aglianico dalla Campania.",
        "price": "58.90",
        "features": [
            "Intenso",
            "Avvolgente"
        ]
    },
    {
        "_id": "117285",
        "name": "Guado al Tasso Bruciato Bolgheri DOC 2015 75cl",
        "category": "Rosso",
        "region": "Toscana",
        "description": "Eccellente vino toscano della tenuta Guado al Tasso.",
        "price": "26.90",
        "features": [
            "Complesso",
            "Fruttato"
        ]
    },
    {
        "_id": "118307",
        "name": "Sartori Cà de' Amarone della Valpolicella DOCG 1,5L",
        "category": "Rosso",
        "region": "Veneto",
        "description": "Amarone della Valpolicella in formato magnum per momenti speciali.",
        "price": "39.90",
        "features": [
            "Intenso",
            "Fruttato"
        ]
    },
    {
        "_id": "124635",
        "name": "Marina Cvetic Montepulciano d'Abruzzo 75cl",
        "category": "Rosso",
        "region": "Abruzzo",
        "description": "Vino robusto e ricco prodotto nella regione dell'Abruzzo.",
        "price": "34.90",
        "features": [
            "Robusto",
            "Fruttato"
        ]
    },
    {
        "_id": "124908",
        "name": "Feudi San Gregorio Cassiopea Greco/Fiano/Rubr. 75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Blend di Greco, Fiano e Rubrato dalla rinomata cantina Feudi San Gregorio.",
        "price": "25.90",
        "features": [
            "Fresco",
            "Fruttato"
        ]
    },
    {
        "_id": "125424",
        "name": "Iovis Tonant Aglianico Irpinia DOCG Asprinio 75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Vino Aglianico di qualità dalla Campania.",
        "price": "20.90",
        "features": [
            "Elegante",
            "Speziato"
        ]
    },
    {
        "_id": "125440",
        "name": "Ocone Piedirosso DOP 1,5L",
        "category": "Rosso",
        "region": "Campania",
        "description": "Vino rosso caratterizzato dalle uve autoctone Piedirosso.",
        "price": "15.90",
        "features": [
            "Fresco",
            "Fruttato"
        ]
    },
    {
        "_id": "125593",
        "name": "Villa Matilde Gotico Taurasi DOCG 75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Vino prestigioso proveniente dalla regione della Campania.",
        "price": "43.90",
        "features": [
            "Complesso",
            "Robusto"
        ]
    },
    {
        "_id": "125594",
        "name": "Vestini Campagnano Casavecchia 75cl",
        "category": "Rosso",
        "region": "Abruzzo",
        "description": "Vino rosso robusto con profilo fruttato e speziato.",
        "price": "19.40",
        "features": [
            "Robusto",
            "Speziato"
        ]
    },
    {
        "_id": "125601",
        "name": "Santa Margherita Pinot Nero Riserva 75cl",
        "category": "Rosso",
        "region": "Veneto",
        "description": "Elegante Pinot Nero invecchiato con cura.",
        "price": "17.50",
        "features": [
            "Elegante",
            "Fruttato"
        ]
    },
    {
        "_id": "127302",
        "name": "Tiare Collio Sauvignon 75cl",
        "category": "Rosso",
        "region": "Friuli-Venezia Giulia",
        "description": "Vino bianco aromatico con uve Sauvignon dalla regione del Friuli-Venezia Giulia.",
        "price": "19.90",
        "features": [
            "Aromatico",
            "Agrumato"
        ]
    },
    {
        "_id": "127304",
        "name": "Vie de Romans Sauvignon 75cl",
        "category": "Rosso",
        "region": "Friuli-Venezia Giulia",
        "description": "Sauvignon di alta qualità dalla rinomata cantina Vie de Romans.",
        "price": "24.90",
        "features": [
            "Elegante",
            "Floreale"
        ]
    },
    {
        "_id": "127308",
        "name": "Antinori Peppoli Chianti Classico DOCG 75cl",
        "category": "Rosso",
        "region": "Toscana",
        "description": "Chianti Classico di grande qualità dalla famosa cantina Antinori.",
        "price": "15.90",
        "features": [
            "Fresco",
            "Fruttato"
        ]
    },
    {
        "_id": "127314",
        "name": "Carpineto Vino Nobile di Montepulciano 75cl",
        "category": "Rosso",
        "region": "Toscana",
        "description": "Vino rosso di prestigio dalla regione della Toscana.",
        "price": "22.90",
        "features": [
            "Strutturato",
            "Fruttato"
        ]
    },
    {
        "_id": "129178",
        "name": "Ceretto Dolcetto d'Alba Rossana 75cl",
        "category": "Rosso",
        "region": "Piemonte",
        "description": "Dolcetto d'Alba con carattere fruttato e fresco.",
        "price": "18.90",
        "features": [
            "Fresco",
            "Fruttato"
        ]
    },
    {
        "_id": "129182",
        "name": "Allegrini Amarone della Valpolicella Classico DOCG 75cl",
        "category": "Rosso",
        "region": "Veneto",
        "description": "Amarone elegante e complesso, prodotto nella regione del Veneto.",
        "price": "72.90",
        "features": [
            "Complesso",
            "Fruttato"
        ]
    },
    {
        "_id": "129184",
        "name": "Ceretto Barbaresco 75cl",
        "category": "Rosso",
        "region": "Piemonte",
        "description": "Barbaresco di alta qualità con profilo complesso.",
        "price": "53.70",
        "features": [
            "Complesso",
            "Fruttato"
        ]
    },
    {
        "_id": "129185",
        "name": "Ceretto Barbera Piana 75cl",
        "category": "Rosso",
        "region": "Piemonte",
        "description": "Barbera Piana con caratteristiche fruttate e fresche.",
        "price": "22.90",
        "features": [
            "Fresco",
            "Fruttato"
        ]
    },
    {
        "_id": "129186",
        "name": "Ceretto Barolo 75cl",
        "category": "Rosso",
        "region": "Piemonte",
        "description": "Barolo di alta qualità, vino rosso iconico della regione Piemontese.",
        "price": "62.30",
        "features": [
            "Elegante",
            "Robusto"
        ]
    },
    {
        "_id": "129187",
        "name": "Ceretto Nebbiolo Bernardina 75cl",
        "category": "Rosso",
        "region": "Piemonte",
        "description": "Nebbiolo con carattere unico e ricco.",
        "price": "28.90",
        "features": [
            "Intenso",
            "Complesso"
        ]
    },
    {
        "_id": "129200",
        "name": "Allegrini Valpolicella Superiore DOC 75cl",
        "category": "Rosso",
        "region": "Veneto",
        "description": "Valpolicella Superiore di alta qualità prodotto dalla rinomata cantina Allegrini.",
        "price": "18.00",
        "features": [
            "Fresco",
            "Fruttato"
        ]
    },
    {
        "_id": "129208",
        "name": "Cesari Giara Ripasso Valpolicella DOC 75cl",
        "category": "Rosso",
        "region": "Veneto",
        "description": "Ripasso Valpolicella con profilo ricco e avvolgente.",
        "price": "16.80",
        "features": [
            "Robusto",
            "Fruttato"
        ]
    },
    {
        "_id": "129214",
        "name": "Allegrini La Grola Amarone della Valpolicella Classico DOCG 75cl",
        "category": "Rosso",
        "region": "Veneto",
        "description": "Amarone della Valpolicella Classico di grande struttura e complessità.",
        "price": "32.90",
        "features": [
            "Complesso",
            "Fruttato"
        ]
    },
    {
        "_id": "129215",
        "name": "Cesari Giara Recioto Valpolicella DOCG 50cl",
        "category": "Rosso",
        "region": "Veneto",
        "description": "Recioto Valpolicella dolce e avvolgente.",
        "price": "31.50",
        "features": [
            "Dolce",
            "Fruttato"
        ]
    },
    {
        "_id": "129231",
        "name": "Terredora Il Principe Aglianico 75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Aglianico strutturato con carattere elegante.",
        "price": "16.90",
        "features": [
            "Strutturato",
            "Speziato"
        ]
    },
    {
        "_id": "129233",
        "name": "Banfi Aska Bolgheri Rosso DOC 75cl",
        "category": "Rosso",
        "region": "Toscana",
        "description": "Vino rosso di Bolgheri con profilo fruttato e speziato.",
        "price": "19.00",
        "features": [
            "Fruttato",
            "Complesso"
        ]
    },
    {
        "_id": "129236",
        "name": "Antinori Solaia 75cl",
        "category": "Rosso",
        "region": "Toscana",
        "description": "Solaia, un'icona toscana, vino rosso di prestigio e complessità.",
        "price": "349.00",
        "features": [
            "Complesso",
            "Fruttato"
        ]
    },
    {
        "_id": "131064",
        "name": "Pasqua Amarone della Valpolicella Classico DOCG 75cl",
        "category": "Rosso",
        "region": "Veneto",
        "description": "Amarone della Valpolicella Classico ricco e avvolgente.",
        "price": "20.90",
        "features": [
            "Robusto",
            "Fruttato"
        ]
    },
    {
        "_id": "131155",
        "name": "Frescobaldi Nipozzano Chianti Rufina Riserva 75cl",
        "category": "Rosso",
        "region": "Toscana",
        "description": "Chianti Rufina Riserva di grande struttura e complessità.",
        "price": "36.90",
        "features": [
            "Complesso",
            "Robusto"
        ]
    },
    {
        "_id": "131295",
        "name": "Damilano Barolo DOCG 75cl",
        "category": "Rosso",
        "region": "Piemonte",
        "description": "Barolo di alta qualità proveniente dalla regione del Piemonte.",
        "price": "21.90",
        "features": [
            "Elegante",
            "Strutturato"
        ]
    },
    {
        "_id": "131296",
        "name": "Damilano Barbaresco DOCG 75cl",
        "category": "Rosso",
        "region": "Piemonte",
        "description": "Barbaresco di grande personalità e finezza.",
        "price": "16.90",
        "features": [
            "Floreale",
            "Avvolgente"
        ]
    },
    {
        "_id": "133550",
        "name": "Feudi San Gregorio Taurasi Riserva Famiglia Papa 75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Taurasi Riserva con profilo complesso e strutturato.",
        "price": "17.90",
        "features": [
            "Complesso",
            "Fruttato"
        ]
    },
    {
        "_id": "135629",
        "name": "Kellerei Cantina Terlan Pinot Noir 75cl",
        "category": "Rosso",
        "region": "Trentino",
        "description": "Pinot Noir elegante proveniente dall'Trentino.",
        "price": "18.90",
        "features": [
            "Elegante",
            "Fruttato"
        ]
    },
    {
        "_id": "135641",
        "name": "Donnafugata Floramundi Cerasuolo di Vittoria DOC 75cl",
        "category": "Rosso",
        "region": "Sicilia",
        "description": "Cerasuolo di Vittoria di grande freschezza e complessità.",
        "price": "16.90",
        "features": [
            "Fresco",
            "Fruttato"
        ]
    },
    {
        "_id": "135651",
        "name": "Antinori Guado al Tasso Bolgheri Superiore DOC 75cl",
        "category": "Rosso",
        "region": "Toscana",
        "description": "Bolgheri Superiore di prestigio con profilo complesso e avvolgente.",
        "price": "99.00",
        "features": [
            "Complesso",
            "Persistente"
        ]
    },
    {
        "_id": "141150",
        "name": "Castello di Ama Chianti Classico 75cl",
        "category": "Rosso",
        "region": "Toscana",
        "description": "Chianti Classico elegante e ben strutturato.",
        "price": "64.90",
        "features": [
            "Elegante",
            "Avvolgente"
        ]
    },
    {
        "_id": "141156",
        "name": "Fattoria dei Barbi Brunello di Montalcino 75cl",
        "category": "Rosso",
        "region": "Toscana",
        "description": "Brunello di Montalcino con carattere robusto e complesso.",
        "price": "36.90",
        "features": [
            "Robusto",
            "Fruttato"
        ]
    },
    {
        "_id": "141158",
        "name": "Frescobaldi Nipozzano Chianti Riserva 75cl",
        "category": "Rosso",
        "region": "Toscana",
        "description": "Chianti Riserva con buona struttura e persistenza.",
        "price": "15.60",
        "features": [
            "Strutturato",
            "Persistente"
        ]
    },
    {
        "_id": "141159",
        "name": "Zenato Amarone della Valpolicella 75cl",
        "category": "Rosso",
        "region": "Veneto",
        "description": "Amarone della Valpolicella ricco e avvolgente.",
        "price": "52.40",
        "features": [
            "Avvolgente",
            "Fruttato"
        ]
    },
    {
        "_id": "141165",
        "name": "Molettieri Aglianico 5 Querce 75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Aglianico strutturato con carattere fruttato intenso.",
        "price": "16.90",
        "features": [
            "Strutturato",
            "Intenso"
        ]
    },
    {
        "_id": "141170",
        "name": "Zenato Ripasso della Valpolicella 75cl",
        "category": "Rosso",
        "region": "Veneto",
        "description": "Ripasso della Valpolicella con ricchezza aromatica e rotondità.",
        "price": "21.90",
        "features": [
            "Robusto",
            "Armonico"
        ]
    },
    {
        "_id": "141173",
        "name": "Antinori Ornellaia 75cl",
        "category": "Rosso",
        "region": "Toscana",
        "description": "Ornellaia, un vino iconico con complessità e eleganza.",
        "price": "299.00",
        "features": [
            "Complesso",
            "Elegante"
        ]
    },
    {
        "_id": "141189",
        "name": "Tommasi Ripasso della Valpolicella 75cl",
        "category": "Rosso",
        "region": "Veneto",
        "description": "Ripasso della Valpolicella con intensità e struttura.",
        "price": "17.90",
        "features": [
            "Intenso",
            "Strutturato"
        ]
    },
    {
        "_id": "141191",
        "name": "Podere 414 Morellino 75cl",
        "category": "Rosso",
        "region": "Toscana",
        "description": "Morellino fresco e fruttato dalla Maremma toscana.",
        "price": "19.90",
        "features": [
            "Fresco",
            "Fruttato"
        ]
    },
    {
        "_id": "141198",
        "name": "Villa Raiano Aglianico Taurasi DOC 75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Aglianico Taurasi con struttura e complessità.",
        "price": "15.90",
        "features": [
            "Strutturato",
            "Complesso"
        ]
    },
    {
        "_id": "141199",
        "name": "Braida Bricco dell'Uccellone Barbera 75cl",
        "category": "Rosso",
        "region": "Piemonte",
        "description": "Barbera dal Bricco dell'Uccellone, potente e elegante.",
        "price": "59.00",
        "features": [
            "Potente",
            "Elegante"
        ]
    },
    {
        "_id": "141498",
        "name": "Campo ai Sassi Rosso Montepulciano 75cl",
        "category": "Rosso",
        "region": "Toscana",
        "description": "Rosso Montepulciano con freschezza e fruttato vivace.",
        "price": "16.90",
        "features": [
            "Fresco",
            "Fruttato"
        ]
    },
    {
        "_id": "141504",
        "name": "Castelgiocondo Brunello di Montalcino 75cl",
        "category": "Rosso",
        "region": "Toscana",
        "description": "Brunello di Montalcino con struttura e longevità.",
        "price": "44.90",
        "features": [
            "Strutturato",
            "Persistente"
        ]
    },
    {
        "_id": "141521",
        "name": "Nino Negri Sfursat 5 Stelle 75cl",
        "category": "Rosso",
        "region": "Lombardia",
        "description": "Vino Sfursat 5 Stelle dall'azienda Nino Negri.",
        "price": "88.90",
        "features": [
            "Robusto",
            "Intenso"
        ]
    },
    {
        "_id": "141522",
        "name": "Caggiano Sala dei Giganti Aglianico 75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Aglianico Sala dei Giganti, potente e strutturato.",
        "price": "25.00",
        "features": [
            "Potente",
            "Strutturato"
        ]
    },
    {
        "_id": "141536",
        "name": "Terlan Pinot Nero 75cl",
        "category": "Rosso",
        "region": "Trentino",
        "description": "Pinot Nero elegante e fruttato dall'Trentino.",
        "price": "19.90",
        "features": [
            "Elegante",
            "Fruttato"
        ]
    },
    {
        "_id": "142543",
        "name": "Fontanafredda Vigna delle Cattaratte 75cl",
        "category": "Rosso",
        "region": "Piemonte",
        "description": "Barolo elegante dalla cantina Fontanafredda.",
        "price": "26.40",
        "features": [
            "Elegante",
            "Complesso"
        ]
    },
    {
        "_id": "142556",
        "name": "Fontanafredda Grignolino Mora 75cl",
        "category": "Rosso",
        "region": "Piemonte",
        "description": "Grignolino Mora, leggero e aromatico.",
        "price": "41.40",
        "features": [
            "Leggero",
            "Aromatico"
        ]
    },
    {
        "_id": "142564",
        "name": "Papa Celso Campantuono Falerno del Massico 75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Falerno del Massico complesso e persistente.",
        "price": "37.40",
        "features": [
            "Complesso",
            "Persistente"
        ]
    },
    {
        "_id": "142565",
        "name": "Cottanera Etna Rosso DOC 75cl",
        "category": "Rosso",
        "region": "Sicilia",
        "description": "Etna Rosso, vino vulcanico dal carattere unico.",
        "price": "20.90",
        "features": [
            "Minerale",
            "Complesso"
        ]
    },
    {
        "_id": "142572",
        "name": "Pio Cesare Barbera d'Alba 75cl",
        "category": "Rosso",
        "region": "Piemonte",
        "description": "Barbera d'Alba fresco e vivace.",
        "price": "16.90",
        "features": [
            "Fresco",
            "Vivace"
        ]
    },
    {
        "_id": "143030",
        "name": "Bersano Barolo DOCG 75cl",
        "category": "Rosso",
        "region": "Piemonte",
        "description": "Barolo con struttura e intensità.",
        "price": "29.90",
        "features": [
            "Strutturato",
            "Intenso"
        ]
    },
    {
        "_id": "143031",
        "name": "Biondi Santi Brunello di Montalcino DOCG 75cl",
        "category": "Rosso",
        "region": "Toscana",
        "description": "Brunello di Montalcino, elegante e longevo.",
        "price": "169.00",
        "features": [
            "Elegante",
            "Persistente"
        ]
    },
    {
        "_id": "143032",
        "name": "Nino Negri Grumello Valtellina DOCG 75cl",
        "category": "Rosso",
        "region": "Lombardia",
        "description": "Grumello Valtellina, leggero e profumato.",
        "price": "15.90",
        "features": [
            "Leggero",
            "Floreale"
        ]
    },
    {
        "_id": "143033",
        "name": "Nino Negri Inferno Valtellina DOCG 75cl",
        "category": "Rosso",
        "region": "Lombardia",
        "description": "Inferno Valtellina, intenso e robusto.",
        "price": "18.90",
        "features": [
            "Intenso",
            "Robusto"
        ]
    },
    {
        "_id": "143034",
        "name": "Nino Negri Sassella Valtellina DOCG 75cl",
        "category": "Rosso",
        "region": "Lombardia",
        "description": "Sassella Valtellina, elegante e strutturato.",
        "price": "17.90",
        "features": [
            "Elegante",
            "Strutturato"
        ]
    },
    {
        "_id": "143035",
        "name": "Boglietti Nebbiolo Langhe DOC 75cl",
        "category": "Rosso",
        "region": "Piemonte",
        "description": "Nebbiolo Langhe, carattere e complessità.",
        "price": "16.40",
        "features": [
            "Elegante",
            "Complesso"
        ]
    },
    {
        "_id": "143036",
        "name": "Travaglini Gattinara DOCG 75cl",
        "category": "Rosso",
        "region": "Piemonte",
        "description": "Gattinara DOCG della cantina Travaglini.",
        "price": "29.90",
        "features": [
            "Elegante",
            "Strutturato"
        ]
    },
    {
        "_id": "143038",
        "name": "Molettieri 5 Querce Taurasi 75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Taurasi 5 Querce, potente e complesso.",
        "price": "49.90",
        "features": [
            "Potente",
            "Complesso"
        ]
    },
    {
        "_id": "143040",
        "name": "Librandi Duca San Felice Ciro Rosso 75cl",
        "category": "Rosso",
        "region": "Calabria",
        "description": "Ciro Rosso, vino rosso della Calabria.",
        "price": "16.90",
        "features": [
            "Fruttato",
            "Leggero"
        ]
    },
    {
        "_id": "143048",
        "name": "Donnafugata Mille e una Notte IGT 75cl",
        "category": "Rosso",
        "region": "Sicilia",
        "description": "Mille e una Notte, vino elegante e complesso.",
        "price": "70.90",
        "features": [
            "Elegante",
            "Complesso"
        ]
    },
    {
        "_id": "143049",
        "name": "Librandi Gravello Rosso 75cl",
        "category": "Rosso",
        "region": "Calabria",
        "description": "Gravello Rosso, vino rosso della Calabria.",
        "price": "27.90",
        "features": [
            "Fruttato",
            "Strutturato"
        ]
    },
    {
        "_id": "143055",
        "name": "Cantina di Nizza La Firma 75cl",
        "category": "Rosso",
        "region": "Piemonte",
        "description": "Vino rosso della Cantina di Nizza.",
        "price": "27.90",
        "features": [
            "Elegante",
            "Fruttato"
        ]
    },
    {
        "_id": "143059",
        "name": "Villa Dora Gelsonero Lacrima di Morro d'Alba 75cl",
        "category": "Rosso",
        "region": "Marche",
        "description": "Lacrima di Morro d'Alba, vino aromatico delle Marche.",
        "price": "15.40",
        "features": [
            "Aromatico",
            "Leggero"
        ]
    },
    {
        "_id": "143063",
        "name": "Cantina Notturno Il Repertorio Aglianico 75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Aglianico della Cantina Notturno.",
        "price": "19.40",
        "features": [
            "Robusto",
            "Complesso"
        ]
    },
    {
        "_id": "143067",
        "name": "Planeta Etna Rosso DOC 75cl",
        "category": "Rosso",
        "region": "Sicilia",
        "description": "Etna Rosso, vino vulcanico di Planeta.",
        "price": "19.90",
        "features": [
            "Minerale",
            "Elegante"
        ]
    },
    {
        "_id": "143071",
        "name": "Papa Celso Conclave Falerno Rosso DOC 1875 75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Falerno Rosso, vino della Cantina Papa Celso.",
        "price": "17.90",
        "features": [
            "Robusto",
            "Persistente"
        ]
    },
    {
        "_id": "143073",
        "name": "Molettieri Renonno Taurasi 75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Taurasi complesso e avvolgente della cantina Molettieri.",
        "price": "32.40",
        "features": [
            "Robusto",
            "Avvolgente"
        ]
    },
    {
        "_id": "143084",
        "name": "Librandi Magno Megonio Rosso 75cl",
        "category": "Rosso",
        "region": "Calabria",
        "description": "Rosso della Calabria, vino fruttato e persistente.",
        "price": "19.40",
        "features": [
            "Fruttato",
            "Persistente"
        ]
    },
    {
        "_id": "143087",
        "name": "Leone de Castris Poliphemo Taurasi 75cl",
        "category": "Rosso",
        "region": "Calabria",
        "description": "Taurasi potente e strutturato della Leone de Castris.",
        "price": "69.90",
        "features": [
            "Potente",
            "Strutturato"
        ]
    },
    {
        "_id": "143088",
        "name": "Leone de Castris Satyricum Aglianico DOC 75cl",
        "category": "Rosso",
        "region": "Calabria",
        "description": "Aglianico della Leone de Castris, elegante e complesso.",
        "price": "48.90",
        "features": [
            "Elegante",
            "Complesso"
        ]
    },
    {
        "_id": "143090",
        "name": "Tiare Cabernet Sauvignon DOC 75cl",
        "category": "Rosso",
        "region": "Friuli-Venezia Giulia",
        "description": "Vino rosso elegante con sentori di frutti neri.",
        "price": "16.90",
        "features": [
            "Elegante",
            "Fruttato"
        ]
    },
    {
        "_id": "143096",
        "name": "Marina Cvetic Syrah IGT 75cl",
        "category": "Rosso",
        "region": "Abruzzo",
        "description": "Syrah robusto con note speziate e di frutti scuri.",
        "price": "27.90",
        "features": [
            "Robusto",
            "Speziato"
        ]
    },
    {
        "_id": "143100",
        "name": "Produttori del Barbaresco DOCG 75cl",
        "category": "Rosso",
        "region": "Piemonte",
        "description": "Barbaresco complesso e strutturato dai Produttori del Barbaresco.",
        "price": "36.90",
        "features": [
            "Complesso",
            "Strutturato"
        ]
    },
    {
        "_id": "143103",
        "name": "Planeta Burdese Menfi DOC 75cl",
        "category": "Rosso",
        "region": "Sicilia",
        "description": "Burdese elegante con influenze internazionali.",
        "price": "22.90",
        "features": [
            "Elegante",
            "Complesso"
        ]
    },
    {
        "_id": "144216",
        "name": "Vini Mandrarossa 1932 Primitivo",
        "category": "Rosso",
        "region": "Sicilia",
        "description": "Primitivo intenso e avvolgente della cantina Vini Mandrarossa.",
        "price": "18.90",
        "features": [
            "Intenso",
            "Avvolgente"
        ]
    },
    {
        "_id": "144507",
        "name": "Bertani Amarone DOCG 75cl",
        "category": "Rosso",
        "region": "Veneto",
        "description": "Amarone potente e ricco della cantina Bertani.",
        "price": "27.90",
        "features": [
            "Potente",
            "Robusto"
        ]
    },
    {
        "_id": "147667",
        "name": "Hofstatter Pinot Nero DOC 75cl",
        "category": "Rosso",
        "region": "Trentino",
        "description": "Pinot Nero elegante e fruttato della cantina Hofstatter.",
        "price": "18.90",
        "features": [
            "Elegante",
            "Fruttato"
        ]
    },
    {
        "_id": "154188",
        "name": "Ceretto Barolo DOCG 75cl 2022",
        "category": "Rosso",
        "region": "Piemonte",
        "description": "Barolo complesso e strutturato della cantina Ceretto.",
        "price": "54.90",
        "features": [
            "Complesso",
            "Strutturato"
        ]
    },
    {
        "_id": "154189",
        "name": "Ceretto Dolcetto d'Alba DOC 75cl 2021",
        "category": "Rosso",
        "region": "Piemonte",
        "description": "Dolcetto d'Alba fresco e fruttato della cantina Ceretto.",
        "price": "17.90",
        "features": [
            "Fresco",
            "Fruttato"
        ]
    },
    {
        "_id": "154190",
        "name": "Ceretto Nebbiolo DOC 75cl 2021",
        "category": "Rosso",
        "region": "Piemonte",
        "description": "Nebbiolo elegante e delicato della cantina Ceretto.",
        "price": "24.90",
        "features": [
            "Elegante",
            "Elegante"
        ]
    },
    {
        "_id": "155159",
        "name": "Di Majo Tintilia Molise DOC 75cl",
        "category": "Rosso",
        "region": "Molise",
        "description": "Tintilia rossa e corposa della cantina Di Majo Norante.",
        "price": "17.90",
        "features": [
            "Floreale",
            "Robusto"
        ]
    },
    {
        "_id": "156347",
        "name": "Cantina Illasi Amarone DOCG 75cl",
        "category": "Rosso",
        "region": "Veneto",
        "description": "Amarone strutturato e avvolgente della Cantina Illasi.",
        "price": "18.90",
        "features": [
            "Strutturato",
            "Avvolgente"
        ]
    },
    {
        "_id": "156544",
        "name": "Marisa Cuomo Furore Rosso DOC 75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Rosso di Furore con carattere e complessità da Marisa Cuomo.",
        "price": "29.90",
        "features": [
            "Elegante",
            "Complesso"
        ]
    },
    {
        "_id": "156548",
        "name": "Astemio Pentita Barolo DOCG 75cl",
        "category": "Rosso",
        "region": "Piemonte",
        "description": "Barolo ricco e complesso dalla cantina Astemia Pentita.",
        "price": "22.90",
        "features": [
            "Robusto",
            "Complesso"
        ]
    },
    {
        "_id": "156917",
        "name": "Cesare Pavese Barbaresco DOCG 75cl",
        "category": "Rosso",
        "region": "Piemonte",
        "description": "Barbaresco elegante e strutturato dedicato a Cesare Pavese.",
        "price": "15.90",
        "features": [
            "Elegante",
            "Strutturato"
        ]
    },
    {
        "_id": "157609",
        "name": "Mastroberardino Serra dei Leudi Taurasi 75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Taurasi potente e armonioso dalla cantina Mastroberardino.",
        "price": "39.90",
        "features": [
            "Potente",
            "Armonico"
        ]
    },
    {
        "_id": "157615",
        "name": "Mastroberardino Magnum Aglianico Irpinia 150cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Magnum di Aglianico Irpinia, espressione della tradizione Mastroberardino.",
        "price": "29.90",
        "features": [
            "Intenso",
            "Persistente"
        ]
    },
    {
        "_id": "157654",
        "name": "Villa Matilde Trio Roccaleoni 3x75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Confezione Trio Roccaleoni con vini strutturati e armoniosi da Villa Matilde.",
        "price": "39.90",
        "features": [
            "Strutturato",
            "Armonico"
        ]
    },
    {
        "_id": "158357",
        "name": "La Custodia Montefalco Rosso DOC 1",
        "category": "Rosso",
        "region": "Umbria",
        "description": "Montefalco Rosso con carattere della cantina La Custodia.",
        "price": "29.90",
        "features": [
            "Elegante",
            "Complesso"
        ]
    },
    {
        "_id": "158359",
        "name": "La Custodia Montefalco Rosso 2x75cl",
        "category": "Rosso",
        "region": "Umbria",
        "description": "Confezione da due bottiglie di Montefalco Rosso della cantina La Custodia.",
        "price": "24.90",
        "features": [
            "Armonico",
            "Fruttato"
        ]
    },
    {
        "_id": "158374",
        "name": "Federiciane Scatola Golfo 2x75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Scatola Golfo con due bottiglie della cantina Federiciane.",
        "price": "19.90",
        "features": [
            "Floreale",
            "Fruttato"
        ]
    },
    {
        "_id": "158383",
        "name": "Duchessa Lucrezia Barbera d'Asti DOCG",
        "category": "Rosso",
        "region": "Piemonte",
        "description": "Barbera d'Asti DOCG elegante della Duchessa Lucrezia.",
        "price": "19.90",
        "features": [
            "Elegante",
            "Fruttato"
        ]
    },
    {
        "_id": "158388",
        "name": "Terre Magre Ribolla Gialla/Pinot 2x75cl",
        "category": "Rosso",
        "region": "Friuli-Venezia Giulia",
        "description": "Confezione da due bottiglie di Ribolla Gialla/Pinot della cantina Terre Magre.",
        "price": "23.90",
        "features": [
            "Floreale",
            "Fruttato"
        ]
    },
    {
        "_id": "158405",
        "name": "Madaudo Sicilia Ilex Asti 3x75cl",
        "category": "Rosso",
        "region": "Sicilia",
        "description": "Confezione da tre bottiglie di Ilex Asti della cantina Madaudo Sicilia.",
        "price": "21.90",
        "features": [
            "Elegante",
            "Fruttato"
        ]
    },
    {
        "_id": "158604",
        "name": "Mezzanotte Duo Campania 2x75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Confezione Duo Campania con due bottiglie della cantina Mezzanotte.",
        "price": "15.90",
        "features": [
            "Leggero",
            "Fruttato"
        ]
    },
    {
        "_id": "158797",
        "name": "Feudi San Gregorio Confezione Coppe/Spada/Bastone",
        "category": "Rosso",
        "region": "Campania",
        "description": "Confezione speciale con vini di eccellenza della cantina Feudi San Gregorio.",
        "price": "59.90",
        "features": [
            "Elegante",
            "Complesso"
        ]
    },
    {
        "_id": "158798",
        "name": "Feudi San Gregorio Confezione Tombola 150cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Confezione Tombola con magnum di vino pregiato della cantina Feudi San Gregorio.",
        "price": "64.90",
        "features": [
            "Strutturato",
            "Intenso"
        ]
    },
    {
        "_id": "163196",
        "name": "Feudi San Gregorio Taurasi DOCG 75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Vino rosso corposo e strutturato con un caratteristico bouquet aromatico.",
        "price": "19.90",
        "features": [
            "Robusto",
            "Intenso"
        ]
    },
    {
        "_id": "191345",
        "name": "Banfi Brunello Montalcino Astuccio 75cl",
        "category": "Rosso",
        "region": "Toscana",
        "description": "Elegante Brunello di Montalcino con astuccio, prodotto dalla celebre cantina Banfi.",
        "price": "26.90",
        "features": [
            "Elegante",
            "Strutturato"
        ]
    },
    {
        "_id": "195263",
        "name": "Marchesi di Barolo Barolo DOCG 75cl",
        "category": "Rosso",
        "region": "Piemonte",
        "description": "Il Barolo DOCG della prestigiosa cantina Marchesi di Barolo, un vino rosso di grande qualità.",
        "price": "27.90",
        "features": [
            "Robusto",
            "Fruttato"
        ]
    },
    {
        "_id": "195271",
        "name": "Marchesi di Barolo Barbaresco 75cl",
        "category": "Rosso",
        "region": "Piemonte",
        "description": "Il Barbaresco della rinomata cantina Marchesi di Barolo, un vino rosso elegante e complesso.",
        "price": "21.90",
        "features": [
            "Elegante",
            "Complesso"
        ]
    },
    {
        "_id": "301088",
        "name": "Donnafugata Frappato Vittoria DOC 75cl",
        "category": "Rosso",
        "region": "Sicilia",
        "description": "Frappato Vittoria DOC della cantina Donnafugata, un vino rosso fresco e fruttato.",
        "price": "17.90",
        "features": [
            "Fresco",
            "Fruttato"
        ]
    },
    {
        "_id": "301089",
        "name": "Donnafugata Vulcano Etna DOC 75cl",
        "category": "Rosso",
        "region": "Sicilia",
        "description": "Vulcano Etna DOC della cantina Donnafugata, un vino rosso vulcanico e mineralo.",
        "price": "21.00",
        "features": [
            "Minerale",
            "Intenso"
        ]
    },
    {
        "_id": "741137",
        "name": "Feudi San Gregorio Serpico Aglianico DOC 75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Il Serpico Aglianico DOC della cantina Feudi San Gregorio, un vino rosso complesso e strutturato.",
        "price": "38.90",
        "features": [
            "Complesso",
            "Strutturato"
        ]
    },
    {
        "_id": "745592",
        "name": "Mastroberardino Historia Taurasi DOCG 75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Historia Taurasi DOCG della cantina Mastroberardino, un vino rosso di alta qualità e tradizione.",
        "price": "35.90",
        "features": [
            "Elegante",
            "Complesso"
        ]
    },
    {
        "_id": "755723",
        "name": "Nugnes Ast.Sing.Onn Antologia 75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Antologia della cantina Nugnes, un vino rosso ricco di storia e tradizione.",
        "price": "22.90",
        "features": [
            "Robusto",
            "Strutturato"
        ]
    },
    {
        "_id": "755775",
        "name": "Tommasi Amarone della Valpolicella DOCG 75cl",
        "category": "Rosso",
        "region": "Veneto",
        "description": "Amarone della Valpolicella DOCG della rinomata cantina Tommasi, un vino rosso corposo e avvolgente.",
        "price": "44.90",
        "features": [
            "Robusto",
            "Avvolgente"
        ]
    },
    {
        "_id": "758131",
        "name": "Cantina del Notaio Il Sigillo 75cl",
        "category": "Rosso",
        "region": "Basilicata",
        "description": "Il Sigillo della Cantina del Notaio, un vino rosso dal carattere unico e distintivo.",
        "price": "39.50",
        "features": [
            "Elegante",
            "Complesso"
        ]
    },
    {
        "_id": "760773",
        "name": "San Marzano Sessantanni Primitivo di Manduria DOP 75cl",
        "category": "Rosso",
        "region": "Puglia",
        "description": "Sessantanni Primitivo di Manduria DOP della cantina San Marzano, un vino rosso potente e strutturato.",
        "price": "26.90",
        "features": [
            "Potente",
            "Strutturato"
        ]
    },
    {
        "_id": "761587",
        "name": "Notte Rossa Primitivo di Manduria",
        "category": "Rosso",
        "region": "Puglia",
        "description": "Notte Rossa Primitivo di Manduria della cantina Notte Rossa, un vino rosso intenso e avvolgente.",
        "price": "23.90",
        "features": [
            "Intenso",
            "Avvolgente"
        ]
    },
    {
        "_id": "766519",
        "name": "Feudi San Gregorio Pagus Marmorensis Taurasi DOCG 75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Taurasi DOCG della cantina Feudi San Gregorio, un vino rosso robusto e complesso.",
        "price": "29.90",
        "features": [
            "Robusto",
            "Complesso"
        ]
    },
    {
        "_id": "774486",
        "name": "La Braccesca Noble Montepulciano d'Abruzzo DOCG 2019 75cl",
        "category": "Rosso",
        "region": "Abruzzo",
        "description": "Montepulciano d'Abruzzo DOCG della cantina La Braccesca, un vino rosso fruttato e vivace.",
        "price": "19.90",
        "features": [
            "Fruttato",
            "Vivace"
        ]
    },
    {
        "_id": "775059",
        "name": "Fontanafredda Barolo DOCG Riserva 75cl",
        "category": "Rosso",
        "region": "Piemonte",
        "description": "Barolo DOCG Riserva della cantina Fontanafredda, un vino rosso elegante e invecchiato.",
        "price": "30.90",
        "features": [
            "Elegante",
            "Persistente"
        ]
    },
    {
        "_id": "775060",
        "name": "Fontanafredda Barolo DOCG Monfalletto 75cl",
        "category": "Rosso",
        "region": "Piemonte",
        "description": "Barolo DOCG Monfalletto della cantina Fontanafredda, un vino rosso strutturato e complesso.",
        "price": "27.50",
        "features": [
            "Strutturato",
            "Complesso"
        ]
    },
    {
        "_id": "775061",
        "name": "Fontanafredda Barbera d'Alba DOCG Riserva 75cl",
        "category": "Rosso",
        "region": "Piemonte",
        "description": "Barbera d'Alba DOCG Riserva della cantina Fontanafredda, un vino rosso armonioso e persistente.",
        "price": "23.00",
        "features": [
            "Armonico",
            "Persistente"
        ]
    },
    {
        "_id": "775611",
        "name": "Illuminati Lumen Riserva Montepulciano d'Abruzzo DOC 75cl",
        "category": "Rosso",
        "region": "Abruzzo",
        "description": "Montepulciano d'Abruzzo DOC della cantina Illuminati, un vino rosso corposo e avvolgente.",
        "price": "39.90",
        "features": [
            "Robusto",
            "Avvolgente"
        ]
    },
    {
        "_id": "842286",
        "name": "Frescobaldi Brunello di Montalcino DOCG 75cl",
        "category": "Rosso",
        "region": "Toscana",
        "description": "Brunello di Montalcino DOCG della cantina Frescobaldi, un vino rosso elegante e strutturato.",
        "price": "19.90",
        "features": [
            "Elegante",
            "Strutturato"
        ]
    },
    {
        "_id": "872945",
        "name": "Fontanafredda Barolo DOCG 75cl",
        "category": "Rosso",
        "region": "Piemonte",
        "description": "Barolo DOCG della cantina Fontanafredda, un vino rosso complesso e raffinato.",
        "price": "21.90",
        "features": [
            "Complesso",
            "Elegante"
        ]
    },
    {
        "_id": "873679",
        "name": "Sartori Amarone della Valpolicella DOC 75cl",
        "category": "Rosso",
        "region": "Veneto",
        "description": "Amarone della Valpolicella DOC della cantina Sartori, un vino rosso potente e avvolgente.",
        "price": "24.90",
        "features": [
            "Potente",
            "Avvolgente"
        ]
    },
    {
        "_id": "CS00080",
        "name": "Aglianico Cenito DOC Luigi Maffini",
        "category": "Rosso",
        "region": "Campania",
        "description": "Aglianico Cenito DOC della cantina Luigi Maffini, un vino rosso intenso e robusto.",
        "price": "29.90",
        "features": [
            "Intenso",
            "Robusto"
        ]
    },
    {
        "_id": "CS00131",
        "name": "Piccini Brunello di Montalcino DOCG 75cl",
        "category": "Rosso",
        "region": "Toscana",
        "description": "Brunello di Montalcino DOCG della cantina Piccini, un vino rosso equilibrato e persistente.",
        "price": "25.90",
        "features": [
            "Armonico",
            "Persistente"
        ]
    },
    {
        "_id": "CS00134",
        "name": "Cantina del Notaio Il Repertorio 75cl",
        "category": "Rosso",
        "region": "Basilicata",
        "description": "Il Repertorio della Cantina del Notaio, un vino rosso fresco e fruttato.",
        "price": "15.90",
        "features": [
            "Fresco",
            "Fruttato"
        ]
    },
    {
        "_id": "CS00147",
        "name": "Masi Amarone Costasera 75cl",
        "category": "Rosso",
        "region": "Veneto",
        "description": "Amarone Costasera della cantina Masi, un vino rosso elegante e potente.",
        "price": "35.00",
        "features": [
            "Elegante",
            "Potente"
        ]
    },
    {
        "_id": "CS00172",
        "name": "Furore Costa d'Amalfi Rosso 75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Un rosso intenso e avvolgente dalla Costa d'Amalfi.",
        "price": "19.90",
        "features": [
            "Intenso",
            "Avvolgente"
        ]
    },
    {
        "_id": "CS00178",
        "name": "Fontanafredda Barolo DOCG 75cl",
        "category": "Rosso",
        "region": "Piemonte",
        "description": "Il prestigioso Barolo della tenuta Fontanafredda.",
        "price": "22.90",
        "features": [
            "Elegante",
            "Robusto"
        ]
    },
    {
        "_id": "CS00180",
        "name": "Fontanafredda Roca 75cl",
        "category": "Rosso",
        "region": "Piemonte",
        "description": "Un vino dal carattere unico, la Roca di Fontanafredda.",
        "price": "18.90",
        "features": [
            "Complesso",
            "Armonico"
        ]
    },
    {
        "_id": "CS00864",
        "name": "Masi Amarone Costasera 1L",
        "category": "Rosso",
        "region": "Veneto",
        "description": "Amarone Costasera, un grande vino in formato magnum.",
        "price": "89.00",
        "features": [
            "Robusto",
            "Elegante"
        ]
    },
    {
        "_id": "CS01509",
        "name": "Alois Trebulanum 750ml",
        "category": "Rosso",
        "region": "Campania",
        "description": "Il Trebulanum della cantina Alois, un vino ricco di storia.",
        "price": "18.90",
        "features": [
            "Persistente",
            "Strutturato"
        ]
    },
    {
        "_id": "CS01574",
        "name": "Lagrein DOC Trentino Walch",
        "category": "Rosso",
        "region": "Trentino",
        "description": "Lagrein della zona dell'Trentino, prodotto dalla cantina Walch.",
        "price": "15.50",
        "features": [
            "Fresco",
            "Fruttato"
        ]
    },
    {
        "_id": "CS02408",
        "name": "Tolos Montepulciano Terra d'Aligi 75cl",
        "category": "Rosso",
        "region": "Abruzzo",
        "description": "Montepulciano della Terra d'Aligi, un vino robusto e avvolgente.",
        "price": "23.90",
        "features": [
            "Robusto",
            "Avvolgente"
        ]
    },
    {
        "_id": "CS02621",
        "name": "Tatone Magnum DOC 6Lt Spinelli",
        "category": "Rosso",
        "region": "Marche",
        "description": "Un magnum di Tatone, un vino di grande qualità.",
        "price": "179.00",
        "features": [
            "Elegante",
            "Complesso"
        ]
    },
    {
        "_id": "CS02622",
        "name": "Tatone Tin Box DOC 3Lt d'Aligi Cantina Spine",
        "category": "Rosso",
        "region": "Marche",
        "description": "Il Tatone in una elegante confezione da 3 litri.",
        "price": "45.00",
        "features": [
            "Elegante",
            "Strutturato"
        ]
    },
    {
        "_id": "CS02623",
        "name": "Tolos Tin Box 3Lt Terra Cantina Spine",
        "category": "Rosso",
        "region": "Abruzzo",
        "description": "Il Tolos in una elegante confezione da 3 litri.",
        "price": "59.00",
        "features": [
            "Elegante",
            "Fruttato"
        ]
    },
    {
        "_id": "CS02624",
        "name": "Tatone DOC 12Lt Terra Cantina Spine",
        "category": "Rosso",
        "region": "Marche",
        "description": "Il Tatone in formato festa da 12 litri.",
        "price": "290.00",
        "features": [
            "Elegante",
            "Intenso"
        ]
    },
    {
        "_id": "CS02625",
        "name": "Tolos 9Lt Terra d'Aligi",
        "category": "Rosso",
        "region": "Abruzzo",
        "description": "Il Tolos in formato festa da 9 litri.",
        "price": "210.00",
        "features": [
            "Intenso",
            "Elegante"
        ]
    },
    {
        "_id": "CS02996",
        "name": "Sassicaia 2017",
        "category": "Rosso",
        "region": "Toscana",
        "description": "Il celebre Sassicaia del 2017, un'icona della produzione vinicola italiana.",
        "price": "250.00",
        "features": [
            "Intenso",
            "Elegante"
        ]
    },
    {
        "_id": "CS03475",
        "name": "Vino Tolos Riserva 6Lt d'Aligi",
        "category": "Rosso",
        "region": "Abruzzo",
        "description": "La riserva di Tolos in formato magnum, un vino di grande qualità.",
        "price": "250.00",
        "features": [
            "Elegante",
            "Strutturato"
        ]
    },
    {
        "_id": "CS03476",
        "name": "Vino Tatone 18Lt Terra d'Aligi",
        "category": "Rosso",
        "region": "Marche",
        "description": "Il Tatone in formato festa da 18 litri, un vino unico nel suo genere.",
        "price": "450.00",
        "features": [
            "Intenso",
            "Complesso"
        ]
    },
    {
        "_id": "CS04841",
        "name": "Sassicaia 2018 Vino",
        "category": "Rosso",
        "region": "Toscana",
        "description": "Il rinomato Sassicaia del 2018, un vino di straordinaria qualità.",
        "price": "320.00",
        "features": [
            "Elegante",
            "Elegante"
        ]
    },
    {
        "_id": "CS06744",
        "name": "Feudi Serpico Aglianico 75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Il Feudi Serpico, un Aglianico di grande carattere.",
        "price": "49.00",
        "features": [
            "Elegante",
            "Intenso"
        ]
    },
    {
        "_id": "CS06745",
        "name": "Feudi Piano Montevergine Riserva 75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Un vino riserva di grande qualità, il Piano Montevergine della cantina Feudi San Gregorio.",
        "price": "39.90",
        "features": [
            "Elegante",
            "Complesso"
        ]
    },
    {
        "_id": "CS06761",
        "name": "Taurasi DOCH 75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Il Taurasi, un vino robusto e avvolgente.",
        "price": "23.50",
        "features": [
            "Robusto",
            "Avvolgente"
        ]
    },
    {
        "_id": "CS06763",
        "name": "Il Falcone Rosso Riserva 75cl Castel del Monte",
        "category": "Rosso",
        "region": "Puglia",
        "description": "Un vino riserva proveniente dalla zona di Castel del Monte, strutturato e avvolgente.",
        "price": "20.00",
        "features": [
            "Strutturato",
            "Avvolgente"
        ]
    },
    {
        "_id": "CS06767",
        "name": "Puer Apuliae Nero di Rivera DOCG 75cl CS",
        "category": "Rosso",
        "region": "Puglia",
        "description": "Il Puer Apuliae Nero di Rivera, un vino elegante e fruttato.",
        "price": "28.50",
        "features": [
            "Elegante",
            "Fruttato"
        ]
    },
    {
        "_id": "CS06769",
        "name": "Triusco Primitivo di DOC 75cl Rivera",
        "category": "Rosso",
        "region": "Puglia",
        "description": "Il Triusco Primitivo di Rivera, un vino giovane e fresco.",
        "price": "16.00",
        "features": [
            "Elegante",
            "Fresco"
        ]
    },
    {
        "_id": "CS07406",
        "name": "Piccini Vino Chianti DOCG 1L",
        "category": "Rosso",
        "region": "Toscana",
        "description": "Il classico Chianti della cantina Piccini, in formato da 1 litro.",
        "price": "15.90",
        "features": [
            "Elegante",
            "Fruttato"
        ]
    },
    {
        "_id": "CS07457",
        "name": "Pian delle Vigne 2017 di Montalcino",
        "category": "Rosso",
        "region": "Toscana",
        "description": "Il Pian delle Vigne, un Brunello di Montalcino elegante e complesso.",
        "price": "55.00",
        "features": [
            "Elegante",
            "Complesso"
        ]
    },
    {
        "_id": "CS07582",
        "name": "Champagne Pommery Brut Rosé 75cl",
        "category": "Rosso",
        "region": "Francia",
        "description": "Champagne rosé dall'eleganza unica, prodotto da Pommery.",
        "price": "59.00",
        "features": [
            "Elegante",
            "Fresco"
        ]
    },
    {
        "_id": "CS08451",
        "name": "Lunelli Montalto Pinot 75cl",
        "category": "Rosso",
        "region": "Trentino",
        "description": "Vino rosso Pinot della cantina Lunelli.",
        "price": "36.90",
        "features": [
            "Fruttato",
            "Armonico"
        ]
    },
    {
        "_id": "CS08452",
        "name": "Lunelli Teuto Biologico 75cl",
        "category": "Rosso",
        "region": "Trentino",
        "description": "Vino biologico della cantina Lunelli.",
        "price": "19.90",
        "features": [
            "Fresco",
            "Armonico"
        ]
    },
    {
        "_id": "CS08453",
        "name": "Lunelli Carapace 75cl",
        "category": "Rosso",
        "region": "Trentino",
        "description": "Vino rosso Carapace della cantina Lunelli.",
        "price": "26.50",
        "features": [
            "Robusto",
            "Persistente"
        ]
    },
    {
        "_id": "CS08454",
        "name": "Lunelli Auritea Cabernet 75cl",
        "category": "Rosso",
        "region": "Trentino",
        "description": "Vino Cabernet della cantina Lunelli, Auritea.",
        "price": "59.90",
        "features": [
            "Intenso",
            "Strutturato"
        ]
    },
    {
        "_id": "CS08455",
        "name": "Lunelli Lunga Attesa",
        "category": "Rosso",
        "region": "Trentino",
        "description": "Vino della cantina Lunelli, Lunga Attesa.",
        "price": "44.50",
        "features": [
            "Elegante",
            "Complesso"
        ]
    },
    {
        "_id": "CS09101",
        "name": "Bios Aglianico IGP Biologico 2017",
        "category": "Rosso",
        "region": "Campania",
        "description": "Vino Aglianico biologico della cantina Bios.",
        "price": "16.90",
        "features": [
            "Fresco",
            "Fruttato"
        ]
    },
    {
        "_id": "CS09108",
        "name": "Gillo IGP Aglianico Paestum 1500ml",
        "category": "Rosso",
        "region": "Campania",
        "description": "Gillo, vino Aglianico della cantina San Salvatore in formato Magnum.",
        "price": "89.00",
        "features": [
            "Elegante",
            "Strutturato"
        ]
    },
    {
        "_id": "CS09109",
        "name": "Aglianico Jungano IGP 3L",
        "category": "Rosso",
        "region": "Campania",
        "description": "Aglianico Jungano della cantina San Salvatore in formato 3 litri.",
        "price": "82.00",
        "features": [
            "Potente",
            "Intenso"
        ]
    },
    {
        "_id": "CS09110",
        "name": "Ceraso IGP Paestum 1L",
        "category": "Rosso",
        "region": "Campania",
        "description": "Vino Ceraso della cantina San Salvatore in formato 1 litro.",
        "price": "26.30",
        "features": [
            "Fruttato",
            "Armonico"
        ]
    },
    {
        "_id": "CS09111",
        "name": "Pian di Stio IGP Paestum",
        "category": "Rosso",
        "region": "Campania",
        "description": "Vino Pian di Stio della cantina San Salvatore.",
        "price": "19.00",
        "features": [
            "Floreale",
            "Elegante"
        ]
    },
    {
        "_id": "CS09112",
        "name": "Pian di Stio IGP Paestum 1L",
        "category": "Rosso",
        "region": "Campania",
        "description": "Vino Pian di Stio della cantina San Salvatore in formato 1 litro.",
        "price": "58.00",
        "features": [
            "Floreale",
            "Elegante"
        ]
    },
    {
        "_id": "CS09114",
        "name": "Trentenare IGP Paestum 1L",
        "category": "Rosso",
        "region": "Campania",
        "description": "Vino Trentenare della cantina San Salvatore in formato 1 litro.",
        "price": "39.90",
        "features": [
            "Complesso",
            "Persistente"
        ]
    },
    {
        "_id": "CS09115",
        "name": "Elea IGP Paestum Greco 2019",
        "category": "Rosso",
        "region": "Campania",
        "description": "Vino Elea Greco della cantina San Salvatore.",
        "price": "26.50",
        "features": [
            "Floreale",
            "Armonico"
        ]
    },
    {
        "_id": "CS09116",
        "name": "Vetere Limited Edizione IGP Rosato 2022 750ml",
        "category": "Rosso",
        "region": "Campania",
        "description": "Edizione limitata del rosato Vetere per l'anno 2022.",
        "price": "17.00",
        "features": [
            "Floreale",
            "Fresco"
        ]
    },
    {
        "_id": "CS09117",
        "name": "Vetere Limited Edizione IGP Rosato San Salvatore",
        "category": "Rosso",
        "region": "Campania",
        "description": "Edizione limitata del rosato Vetere della cantina San Salvatore.",
        "price": "37.00",
        "features": [
            "Fruttato",
            "Elegante"
        ]
    },
    {
        "_id": "CS09121",
        "name": "Vino Spumante Gioi Extreme Rosé 2019",
        "category": "Rosso",
        "region": "Sardegna",
        "description": "Vino spumante rosé di qualità straordinaria.",
        "price": "32.90",
        "features": [
            "Floreale",
            "Elegante"
        ]
    },
    {
        "_id": "CS09122",
        "name": "Vino Spumante Gioi Dosage Metodo Classico Rosé 2019",
        "category": "Rosso",
        "region": "Sardegna",
        "description": "Vino spumante rosé con metodo classico di dosaggio.",
        "price": "32.90",
        "features": [
            "Fresco",
            "Persistente"
        ]
    },
    {
        "_id": "CS09165",
        "name": "Pian di Montena IGP",
        "category": "Rosso",
        "region": "Campania",
        "description": "Vino rosso della cantina Pian di Montena.",
        "price": "15.90",
        "features": [
            "Fruttato",
            "Armonico"
        ]
    },
    {
        "_id": "CS09289",
        "name": "Cilento Aglianico DOP Marino 75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Vino Aglianico della denominazione d'origine protetta Cilento.",
        "price": "24.90",
        "features": [
            "Strutturato",
            "Persistente"
        ]
    },
    {
        "_id": "CS05544",
        "name": "Franciacorta Rosé Le Marchesine",
        "category": "Rosato",
        "region": "Lombardia",
        "description": "Franciacorta rosé della cantina Le Marchesine.",
        "price": "22.90",
        "features": [
            "Elegante",
            "Fresco"
        ]
    },
    {
        "_id": "CS06753",
        "name": "Franciacorta Rosé Brut Cofanetto Bellavista",
        "category": "Rosato",
        "region": "Lombardia",
        "description": "Franciacorta rosé brut con cofanetto della cantina Bellavista.",
        "price": "58.80",
        "features": [
            "Elegante",
            "Complesso"
        ]
    },
    {
        "_id": "CS07366",
        "name": "Aglianico Rosato Vetere 6L Mathusa",
        "category": "Rosato",
        "region": "Campania",
        "description": "Aglianico Rosato della cantina Vetere in formato 6 litri.",
        "price": "155.00",
        "features": [
            "Floreale",
            "Intenso"
        ]
    },
    {
        "_id": "CS09118",
        "name": "Vetere Limited Edizione IGP Rosato 3L San Salvatore",
        "category": "Rosato",
        "region": "Campania",
        "description": "Edizione limitata del rosato Vetere della cantina San Salvatore in formato 3 litri.",
        "price": "89.50",
        "features": [
            "Fruttato",
            "Elegante"
        ]
    },
    {
        "_id": "CS09119",
        "name": "Vetere Limited Edizione IGP Rosato 6L San Salvatore",
        "category": "Rosato",
        "region": "Campania",
        "description": "Edizione limitata del rosato Vetere della cantina San Salvatore in formato 6 litri.",
        "price": "163.00",
        "features": [
            "Fruttato",
            "Elegante"
        ]
    },
    {
        "_id": "140787",
        "name": "Chateau Whispering Angel 75cl",
        "category": "Vino Estero",
        "region": "Francia",
        "description": "Vino francese della cantina Chateau Whispering Angel.",
        "price": "18.90",
        "features": [
            "Floreale",
            "Elegante"
        ]
    },
    {
        "_id": "141505",
        "name": "Domaines Ott Bandol Blanc 75cl",
        "category": "Vino Estero",
        "region": "Francia",
        "description": "Vino francese bianco della cantina Domaines Ott.",
        "price": "34.90",
        "features": [
            "Fruttato",
            "Complesso"
        ]
    },
    {
        "_id": "141508",
        "name": "Domaines Ott Rosé Bandol 75cl",
        "category": "Vino Estero",
        "region": "Francia",
        "description": "Vino francese rosé della cantina Domaines Ott.",
        "price": "46.90",
        "features": [
            "Floreale",
            "Elegante"
        ]
    },
    {
        "_id": "142544",
        "name": "Albert Pic Chablis Saint-Pierre 75cl",
        "category": "Vino Estero",
        "region": "Francia",
        "description": "Chablis francese della cantina Albert Pic.",
        "price": "34.90",
        "features": [
            "Minerale",
            "Persistente"
        ]
    },
    {
        "_id": "142545",
        "name": "Lafond Sancerre Sauvignon 75cl",
        "category": "Vino Estero",
        "region": "Francia",
        "description": "Sancerre Sauvignon francese della cantina Lafond.",
        "price": "38.90",
        "features": [
            "Fruttato",
            "Elegante"
        ]
    },
    {
        "_id": "142550",
        "name": "Regnard Chablis Grand Regnard 75cl",
        "category": "Vino Estero",
        "region": "Francia",
        "description": "Chablis Grand Regnard francese della cantina Regnard.",
        "price": "55.90",
        "features": [
            "Complesso",
            "Intenso"
        ]
    },
    {
        "_id": "142555",
        "name": "Faiveley Bourgogne Pinot Noir 75cl",
        "category": "Vino Estero",
        "region": "Francia",
        "description": "Bourgogne Pinot Noir francese della cantina Faiveley.",
        "price": "39.90",
        "features": [
            "Floreale",
            "Elegante"
        ]
    },
    {
        "_id": "142567",
        "name": "Chateau Latour à Pomerol 75cl",
        "category": "Vino Estero",
        "region": "Francia",
        "description": "Vino francese della cantina Chateau Latour à Pomerol.",
        "price": "144.00",
        "features": [
            "Fruttato",
            "Intenso"
        ]
    },
    {
        "_id": "142569",
        "name": "Gilbert Picq Chablis 2018 75cl",
        "category": "Vino Estero",
        "region": "Francia",
        "description": "Chablis francese della cantina Gilbert Picq.",
        "price": "33.40",
        "features": [
            "Minerale",
            "Elegante"
        ]
    },
    {
        "_id": "142571",
        "name": "Chateau Gravas Sauternes 75cl",
        "category": "Vino Estero",
        "region": "Francia",
        "description": "Sauternes francese della cantina Chateau Gravas.",
        "price": "37.40",
        "features": [
            "Dolce",
            "Intenso"
        ]
    },
    {
        "_id": "143072",
        "name": "Minuty Rosé Prestige 75cl",
        "category": "Vino Estero",
        "region": "Francia",
        "description": "Vino rosé Prestige della cantina Minuty.",
        "price": "27.90",
        "features": [
            "Floreale",
            "Elegante"
        ]
    },
    {
        "_id": "143094",
        "name": "Miraval Rosé Côtes de Provence 75cl",
        "category": "Vino Estero",
        "region": "Francia",
        "description": "Vino rosé della rinomata tenuta Miraval nelle Côtes de Provence.",
        "price": "30.40",
        "features": [
            "Floreale",
            "Elegante"
        ]
    },
    {
        "_id": "149251",
        "name": "Moillard Chablis AOC 75cl",
        "category": "Vino Estero",
        "region": "Francia",
        "description": "Chablis AOC della cantina Moillard.",
        "price": "24.90",
        "features": [
            "Minerale",
            "Fresco"
        ]
    },
    {
        "_id": "151589",
        "name": "Chateau Timberlay Bordeaux 75cl",
        "category": "Vino Estero",
        "region": "Francia",
        "description": "Vino rosso della cantina Chateau Timberlay a Bordeaux.",
        "price": "27.90",
        "features": [
            "Fruttato",
            "Robusto"
        ]
    },
    {
        "_id": "155429",
        "name": "Chateau Bord Sauterne 75cl",
        "category": "Vino Estero",
        "region": "Francia",
        "description": "Vino Sauterne della cantina Chateau Bord.",
        "price": "22.90",
        "features": [
            "Dolce",
            "Persistente"
        ]
    },
    {
        "_id": "155443",
        "name": "Pierre and Bourg Pinot Noir 75cl",
        "category": "Vino Estero",
        "region": "Francia",
        "description": "Pinot Noir della cantina Pierre and Bourg.",
        "price": "19.90",
        "features": [
            "Fruttato",
            "Elegante"
        ]
    },
    {
        "_id": "157593",
        "name": "Chateau L. Cais. Bois 2x75cl",
        "category": "Vino Estero",
        "region": "Francia",
        "description": "Confezione di due vini della cantina Chateau L. Cais. Bois.",
        "price": "27.90",
        "features": [
            "Fruttato",
            "Complesso"
        ]
    },
    {
        "_id": "141545",
        "name": "Francis Coppola Cabernet Sauvignon 75cl",
        "category": "Vino Estero",
        "region": "California",
        "description": "Cabernet Sauvignon della cantina Francis Coppola.",
        "price": "35.40",
        "features": [
            "Fruttato",
            "Robusto"
        ]
    },
    {
        "_id": "125979",
        "name": "Sake Kagatobi Gokkan 72cl",
        "category": "Vino Estero",
        "region": "Giappone",
        "description": "Sake giapponese della cantina Kagatobi.",
        "price": "25.90",
        "features": [
            "Complesso",
            "Elegante"
        ]
    },
    {
        "_id": "143097",
        "name": "Bassermann-Jordan Riesling Trocken 75cl",
        "category": "Vino Estero",
        "region": "Germania",
        "description": "Riesling Trocken della cantina Bassermann-Jordan.",
        "price": "24.90",
        "features": [
            "Floreale",
            "Intenso"
        ]
    },
    {
        "_id": "774154",
        "name": "Terrazas Selection Malbec 75cl",
        "category": "Vino Liquoroso",
        "region": "Argentina",
        "description": "Malbec della cantina Terrazas Selection.",
        "price": "21.90",
        "features": [
            "Fruttato",
            "Intenso"
        ]
    },
    {
        "_id": "CS02090",
        "name": "Deaureo Passito Bianco Paestum Cobellis 50cl",
        "category": "Vino Liquoroso",
        "region": "Italia",
        "description": "Passito bianco della cantina Deaureo a Paestum.",
        "price": "40.00",
        "features": [
            "Dolce",
            "Persistente"
        ]
    },
    {
        "_id": "CS02091",
        "name": "Derubro Passito Rosso IGP Cobellis 50cl",
        "category": "Rosso",
        "region": "Italia",
        "description": "Passito rosso della cantina.",
        "price": "40.00",
        "features": [
            "Dolce",
            "Armonico"
        ]
    },
    {
        "_id": "109628",
        "name": "Fontanafredda Barolo Chinato 500ml",
        "category": "Rosso",
        "region": "Piemonte",
        "description": "Aromatizzato con erbe e spezie, il Barolo Chinato offre un'esperienza unica.",
        "price": "17.90",
        "features": [
            "Intenso",
            "Armonico"
        ]
    },
    {
        "_id": "141530",
        "name": "Graham Late Bottled Vintage Porto 2013 75cl",
        "category": "Vino Liquoroso",
        "region": "Portogallo",
        "description": "Liquoroso portoghese di alta qualità con carattere fruttato e struttura complessa.",
        "price": "29.90",
        "features": [
            "Fruttato",
            "Complesso"
        ]
    },
    {
        "_id": "142546",
        "name": "Le Compte Calvados 75cl",
        "category": "Vino Liquoroso",
        "region": "Francia",
        "description": "Calvados di alta qualità ottenuto dalla distillazione di mele selezionate.",
        "price": "36.40",
        "features": [
            "Fruttato",
            "Complesso"
        ]
    },
    {
        "_id": "117284",
        "name": "Cà del Bosco Cervaro della Sala 2019 75cl",
        "category": "Bianco",
        "region": "Lombardia",
        "description": "Vino bianco italiano di alta qualità con note fruttate e minerali.",
        "price": "64.90",
        "features": [
            "Fruttato",
            "Minerale"
        ]
    },
    {
        "_id": "125596",
        "name": "Jermann Sauvignon 2019 75cl",
        "category": "Bianco",
        "region": "Friuli-Venezia Giulia",
        "description": "Eccellente Sauvignon italiano con profumi intensi di frutta esotica.",
        "price": "25.90",
        "features": [
            "Intenso",
            "Fruttato"
        ]
    },
    {
        "_id": "127006",
        "name": "Ocone Cassiopea 2 Bottiglie Rosso/Bianco 75cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Duo di vini italiano, uno rosso e uno bianco, perfetto per ogni occasione.",
        "price": "20.90",
        "features": [
            "Armonico",
            "Fresco"
        ]
    },
    {
        "_id": "127261",
        "name": "Mastroberardino Villa dei Misteri 2016 75cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Vino bianco italiano di prestigio con una complessità aromatica straordinaria.",
        "price": "110.00",
        "features": [
            "Complesso",
            "Elegante"
        ]
    },
    {
        "_id": "127271",
        "name": "Maffini Kratos Fiano 75cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Fiano italiano con freschezza e note floreali, perfetto per l'estate.",
        "price": "19.90",
        "features": [
            "Fresco",
            "Floreale"
        ]
    },
    {
        "_id": "127275",
        "name": "Elena Walch Gewürztraminer Kastelaz 75cl",
        "category": "Bianco",
        "region": "Trentino",
        "description": "Gewürztraminer italiano con note speziate e avvolgenti.",
        "price": "39.90",
        "features": [
            "Speziato",
            "Avvolgente"
        ]
    },
    {
        "_id": "127287",
        "name": "Preapositus Kern 75cl",
        "category": "Bianco",
        "region": "Trentino",
        "description": "Vino bianco italiano dal carattere fresco e fruttato.",
        "price": "19.90",
        "features": [
            "Fresco",
            "Fruttato"
        ]
    },
    {
        "_id": "127292",
        "name": "Elena Walch Beyond the Clouds DOP 75cl",
        "category": "Bianco",
        "region": "Trentino",
        "description": "Blend italiano armonioso con caratteristiche complesse e strutturate.",
        "price": "50.50",
        "features": [
            "Armonico",
            "Complesso"
        ]
    },
    {
        "_id": "127298",
        "name": "Jermann Tunina Vino della Terra 2019 75cl",
        "category": "Bianco",
        "region": "Friuli-Venezia Giulia",
        "description": "Vino bianco italiano con profondità e ricchezza di aromi.",
        "price": "59.90",
        "features": [
            "Complesso",
            "Avvolgente"
        ]
    },
    {
        "_id": "127299",
        "name": "Jermann Capo Martino IGT 2019 75cl",
        "category": "Bianco",
        "region": "Friuli-Venezia Giulia",
        "description": "Capolavoro italiano con note fruttate e floreali.",
        "price": "59.90",
        "features": [
            "Fruttato",
            "Floreale"
        ]
    },
    {
        "_id": "127300",
        "name": "Jermann Were Dreams IGP 2019 75cl",
        "category": "Bianco",
        "region": "Friuli-Venezia Giulia",
        "description": "Vino italiano che realizza sogni con un profilo aromatico unico.",
        "price": "55.90",
        "features": [
            "Complesso",
            "Elegante"
        ]
    },
    {
        "_id": "127301",
        "name": "Jermann Ribolla Gialla IGT Vinnae 2019 75cl",
        "category": "Bianco",
        "region": "Friuli-Venezia Giulia",
        "description": "Ribolla Gialla italiana con freschezza e acidità equilibrata.",
        "price": "24.40",
        "features": [
            "Fresco",
            "Armonico"
        ]
    },
    {
        "_id": "129218",
        "name": "Cantina Astroni Strione Falanghina 75cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Falanghina italiana con note fruttate e freschezza vibrante.",
        "price": "22.50",
        "features": [
            "Fruttato",
            "Fresco"
        ]
    },
    {
        "_id": "138194",
        "name": "San Salvatore Trentenare Fiano IGP Paestum 75cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Fiano italiano IGP con profumo intenso e freschezza avvolgente.",
        "price": "17.90",
        "features": [
            "Intenso",
            "Fresco"
        ]
    },
    {
        "_id": "138195",
        "name": "San Salvatore Pian di Stio Fiano IGP Paestum 75cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Fiano italiano IGP con complessità aromatica e persistenza gustativa.",
        "price": "32.00",
        "features": [
            "Complesso",
            "Persistente"
        ]
    },
    {
        "_id": "141166",
        "name": "Livio Felluga Sharis 75cl",
        "category": "Bianco",
        "region": "Friuli-Venezia Giulia",
        "description": "Vino bianco italiano IGP con freschezza e note fruttate.",
        "price": "15.90",
        "features": [
            "Fresco",
            "Fruttato"
        ]
    },
    {
        "_id": "141175",
        "name": "Les Crêtes Chardonnay 75cl",
        "category": "Bianco",
        "region": "Valle d'Aosta",
        "description": "Chardonnay italiano IGP con ricchezza aromatica e finezza al palato.",
        "price": "19.40",
        "features": [
            "Avvolgente",
            "Elegante"
        ]
    },
    {
        "_id": "141513",
        "name": "Antinori Conte Vipera 75cl",
        "category": "Bianco",
        "region": "Toscana",
        "description": "Vino bianco italiano IGP con eleganza e complessità strutturale.",
        "price": "29.40",
        "features": [
            "Elegante",
            "Complesso"
        ]
    },
    {
        "_id": "141533",
        "name": "La Rivolta Sogno Rivolta 75cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Vino bianco italiano IGP con vivacità e sentori fruttati.",
        "price": "17.40",
        "features": [
            "Vivace",
            "Fruttato"
        ]
    },
    {
        "_id": "147657",
        "name": "Casale Giglio Antinoo IGT 75cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Vino bianco italiano IGT con armonia e persistenza gustativa.",
        "price": "18.90",
        "features": [
            "Armonico",
            "Persistente"
        ]
    },
    {
        "_id": "154897",
        "name": "San Salvatore Trentenare 75cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Vino bianco italiano IGP con intensità e freschezza equilibrata.",
        "price": "18.90",
        "features": [
            "Intenso",
            "Fresco"
        ]
    },
    {
        "_id": "157533",
        "name": "De Lisio Falanghina 75cl Expo 210P",
        "category": "Bianco",
        "region": "Campania",
        "description": "Falanghina italiana IGP con complessità e struttura.",
        "price": "417.00",
        "features": [
            "Complesso",
            "Strutturato"
        ]
    },
    {
        "_id": "158387",
        "name": "Terre Magre Ribolla Gialla",
        "category": "Bianco",
        "region": "Friuli-Venezia Giulia",
        "description": "Ribolla Gialla italiana IGP con eleganza e freschezza persistente.",
        "price": "29.90",
        "features": [
            "Elegante",
            "Fresco"
        ]
    },
    {
        "_id": "745505",
        "name": "Mastroberardino Aglianico Astroni Magliano 75cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Aglianico italiano IGP con struttura robusta e tannini equilibrati.",
        "price": "25.90",
        "features": [
            "Robusto",
            "Strutturato"
        ]
    },
    {
        "_id": "771524",
        "name": "Castello San Muffato Umbria IGT 2015 50cl",
        "category": "Bianco",
        "region": "Umbria",
        "description": "Vino bianco italiano IGP con complessità e dolcezza avvolgente.",
        "price": "43.40",
        "features": [
            "Complesso",
            "Dolce"
        ]
    },
    {
        "_id": "772156",
        "name": "Falanghina IGT Benevento Spina 75cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Vino bianco italiano IGP con profumi floreali e freschezza persistente.",
        "price": "73.00",
        "features": [
            "Floreale",
            "Fresco"
        ]
    },
    {
        "_id": "772161",
        "name": "Chardonnay Varietale Bianco Spina 75cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Chardonnay italiano IGP con note fruttate e eleganza al palato.",
        "price": "75.00",
        "features": [
            "Complesso",
            "Avvolgente"
        ]
    },
    {
        "_id": "774609",
        "name": "Mastroberardino Neroameta Bianco 75cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Vino bianco italiano IGP con freschezza e bouquet aromatico.",
        "price": "16.00",
        "features": [
            "Fresco",
            "Aromatico"
        ]
    },
    {
        "_id": "CS00038",
        "name": "Fiano Bio Pian Di Stio IGP 75cl San Salvatore",
        "category": "Bianco",
        "region": "Campania",
        "description": "Vino bianco italiano IGP biologico con ricchezza e complessità.",
        "price": "26.50",
        "features": [
            "Avvolgente",
            "Complesso"
        ]
    },
    {
        "_id": "CS00044",
        "name": "Fiano Trentenare IGT 75cl San Salvatore",
        "category": "Bianco",
        "region": "Campania",
        "description": "Vino bianco italiano IGT con fragranza e armonia gustativa.",
        "price": "16.00",
        "features": [
            "Floreale",
            "Armonico"
        ]
    },
    {
        "_id": "CS00045",
        "name": "Greco Bio Calpazio IGP 75cl San Salvatore",
        "category": "Bianco",
        "region": "Campania",
        "description": "Vino bianco italiano IGP biologico con freschezza e mineralità.",
        "price": "17.00",
        "features": [
            "Fresco",
            "Minerale"
        ]
    },
    {
        "_id": "CS00082",
        "name": "Fiano Valentina IGT 75cl Rotolo",
        "category": "Bianco",
        "region": "Campania",
        "description": "Vino bianco italiano IGT con eleganza e finezza.",
        "price": "16.50",
        "features": [
            "Elegante",
            "Elegante"
        ]
    },
    {
        "_id": "CS02095",
        "name": "Eleanico Bianco IGP Coda di Volpe Cobellis",
        "category": "Bianco",
        "region": "Campania",
        "description": "Vino bianco italiano IGP con freschezza e profumi fruttati.",
        "price": "25.00",
        "features": [
            "Fresco",
            "Fruttato"
        ]
    },
    {
        "_id": "117289",
        "name": "Antinori Tignanello Rosso IGT 2019 75cl",
        "category": "Rosso",
        "region": "Toscana",
        "description": "Vino rosso italiano IGT con complessità e struttura avvolgente.",
        "price": "129.00",
        "features": [
            "Complesso",
            "Avvolgente"
        ]
    },
    {
        "_id": "CS00041",
        "name": "Fiano Cecerale IGP 75cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Vino bianco italiano IGP con freschezza e bouquet floreale.",
        "price": "17.00",
        "features": [
            "Fresco",
            "Floreale"
        ]
    },
    {
        "_id": "CS00867",
        "name": "Kratos DOP Cilento Fiano 2022 Maffini 75cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Vino bianco italiano DOP Cilento con profumi fruttati e struttura armoniosa.",
        "price": "19.90",
        "features": [
            "Fruttato",
            "Armonico"
        ]
    },
    {
        "_id": "CS02092",
        "name": "Crai DOP Cilento Fiano 2013 Cobellis 75cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Vino bianco italiano DOP Cilento con eleganza e persistenza.",
        "price": "16.00",
        "features": [
            "Elegante",
            "Persistente"
        ]
    },
    {
        "_id": "CS05904",
        "name": "Mazzella Per' E' Palummo 75cl",
        "category": "Bianco",
        "region": "Campania",
        "description": "Vino bianco italiano IGP con vivacità e carattere fruttato.",
        "price": "15.50",
        "features": [
            "Vivace",
            "Fruttato"
        ]
    },
    {
        "_id": "127272",
        "name": "Maffini Kleos 75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Vino rosso italiano IGP con carattere e profondità.",
        "price": "20.40",
        "features": [
            "Elegante",
            "Complesso"
        ]
    },
    {
        "_id": "127276",
        "name": "Elena Walch Pinot Ludwig 75cl",
        "category": "Rosso",
        "region": "Trentino",
        "description": "Vino rosso italiano IGP elegante e fruttato con uve Pinot Nero.",
        "price": "39.90",
        "features": [
            "Elegante",
            "Fruttato"
        ]
    },
    {
        "_id": "127296",
        "name": "Frescobaldi Lucente 75cl",
        "category": "Rosso",
        "region": "Toscana",
        "description": "Vino rosso italiano IGP armonico e dal gusto avvolgente.",
        "price": "34.40",
        "features": [
            "Armonico",
            "Avvolgente"
        ]
    },
    {
        "_id": "127978",
        "name": "Feudi San Gregorio Patrimà Rosso IGT 75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Vino rosso italiano IGT complesso e di grande struttura.",
        "price": "79.90",
        "features": [
            "Complesso",
            "Strutturato"
        ]
    },
    {
        "_id": "129196",
        "name": "Hofstätter Lagrein 75cl",
        "category": "Rosso",
        "region": "Trentino",
        "description": "Vino rosso italiano IGP dal carattere fruttato e avvolgente.",
        "price": "17.40",
        "features": [
            "Fruttato",
            "Avvolgente"
        ]
    },
    {
        "_id": "129197",
        "name": "Hofstätter Merlot 75cl",
        "category": "Rosso",
        "region": "Trentino",
        "description": "Vino rosso italiano IGP leggero e piacevolmente tannico.",
        "price": "16.00",
        "features": [
            "Leggero",
            "Strutturato"
        ]
    },
    {
        "_id": "129198",
        "name": "Hofstätter Cabernet Sauvignon 75cl",
        "category": "Rosso",
        "region": "Trentino",
        "description": "Vino rosso italiano IGP con note di frutta nera e spezie.",
        "price": "15.90",
        "features": [
            "Fruttato",
            "Speziato"
        ]
    },
    {
        "_id": "129201",
        "name": "Allegrini Palazzo della Torre IGT 75cl",
        "category": "Rosso",
        "region": "Veneto",
        "description": "Vino rosso italiano IGT avvolgente con sentori di frutta matura.",
        "price": "19.90",
        "features": [
            "Avvolgente",
            "Fruttato"
        ]
    },
    {
        "_id": "129213",
        "name": "Allegrini La Grola IGT 75cl",
        "category": "Rosso",
        "region": "Veneto",
        "description": "Vino rosso italiano IGT corposo e strutturato con uve Corvina.",
        "price": "26.50",
        "features": [
            "Robusto",
            "Strutturato"
        ]
    },
    {
        "_id": "129219",
        "name": "Astroni Coda di Pecora Piedirosso DOP 75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Vino rosso italiano IGP dal carattere unico del vitigno Piedirosso.",
        "price": "29.50",
        "features": [
            "Complesso",
            "Fruttato"
        ]
    },
    {
        "_id": "138198",
        "name": "San Salvatore Calpazio Greco IGP Paestum 75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Vino rosso italiano IGP elegante con uve Greco provenienti da Paestum.",
        "price": "19.90",
        "features": [
            "Elegante",
            "Fruttato"
        ]
    },
    {
        "_id": "138199",
        "name": "San Salvatore Jungano Aglianico IGP Paestum 75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Vino rosso italiano IGP avvolgente con uve Aglianico provenienti da Paestum.",
        "price": "19.90",
        "features": [
            "Avvolgente",
            "Robusto"
        ]
    },
    {
        "_id": "141151",
        "name": "Franz Haas Pinot Nero 75cl",
        "category": "Rosso",
        "region": "Trentino",
        "description": "Vino rosso italiano IGP elegante con uve di Pinot Nero.",
        "price": "29.90",
        "features": [
            "Elegante",
            "Fruttato"
        ]
    },
    {
        "_id": "141154",
        "name": "Antinori Tignanello 150cl",
        "category": "Rosso",
        "region": "Toscana",
        "description": "Vino rosso italiano IGP prestigioso con blend di uve Sangiovese, Cabernet Sauvignon, e Cabernet Franc.",
        "price": "289.00",
        "features": [
            "Elegante",
            "Complesso"
        ]
    },
    {
        "_id": "141167",
        "name": "Le Volte Tenuta Ornellaia 75cl",
        "category": "Rosso",
        "region": "Toscana",
        "description": "Vino rosso italiano IGP armonico con blend di uve Merlot, Sangiovese, e Cabernet Sauvignon.",
        "price": "24.90",
        "features": [
            "Armonico",
            "Complesso"
        ]
    },
    {
        "_id": "141169",
        "name": "Terra di Lavoro 75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Vino rosso italiano IGP robusto con uve Aglianico e Piedirosso.",
        "price": "57.90",
        "features": [
            "Elegante",
            "Robusto"
        ]
    },
    {
        "_id": "141502",
        "name": "Jermann Pinot Nero Red Angel 75cl",
        "category": "Rosso",
        "region": "Friuli-Venezia Giulia",
        "description": "Vino rosso italiano IGP intrigante con uve di Pinot Nero.",
        "price": "25.90",
        "features": [
            "Complesso",
            "Fruttato"
        ]
    },
    {
        "_id": "141512",
        "name": "Montiano Falesco 75cl",
        "category": "Rosso",
        "region": "Lazio",
        "description": "Vino rosso italiano IGP ricco con uve Merlot.",
        "price": "69.90",
        "features": [
            "Robusto",
            "Fruttato"
        ]
    },
    {
        "_id": "141520",
        "name": "Naima Aglianico Paestum 75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Vino rosso italiano IGP avvolgente con uve Aglianico provenienti da Paestum.",
        "price": "32.90",
        "features": [
            "Avvolgente",
            "Robusto"
        ]
    },
    {
        "_id": "141525",
        "name": "Livio Felluga Vertigo Rosso 75cl",
        "category": "Rosso",
        "region": "Friuli-Venezia Giulia",
        "description": "Vino rosso italiano IGP fresco con blend di uve Merlot e Refosco dal Peduncolo Rosso.",
        "price": "15.60",
        "features": [
            "Fresco",
            "Complesso"
        ]
    },
    {
        "_id": "141535",
        "name": "Gianfranco Fino Es Primitivo 75cl",
        "category": "Rosso",
        "region": "Puglia",
        "description": "Vino rosso italiano IGP intenso con uve Primitivo.",
        "price": "69.90",
        "features": [
            "Intenso",
            "Fruttato"
        ]
    },
    {
        "_id": "141546",
        "name": "Marion Cabernet Sauvignon 75cl",
        "category": "Rosso",
        "region": "Veneto",
        "description": "Vino rosso italiano IGP strutturato con uve Cabernet Sauvignon.",
        "price": "33.40",
        "features": [
            "Strutturato",
            "Robusto"
        ]
    },
    {
        "_id": "142551",
        "name": "Montevetrano Rosso IGT 75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Vino rosso italiano IGT complesso con uve Aglianico, Cabernet Sauvignon e Merlot.",
        "price": "69.90",
        "features": [
            "Elegante",
            "Complesso"
        ]
    },
    {
        "_id": "142557",
        "name": "Tenuta S. Guido Le Difese 75cl",
        "category": "Rosso",
        "region": "Toscana",
        "description": "Vino rosso italiano IGT armonioso con blend di uve Cabernet Sauvignon e Sangiovese.",
        "price": "31.90",
        "features": [
            "Armonico",
            "Complesso"
        ]
    },
    {
        "_id": "142566",
        "name": "Montevetrano Core Rosso 75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Vino rosso italiano IGT fresco con uve Aglianico, Cabernet Sauvignon e Merlot.",
        "price": "19.90",
        "features": [
            "Fresco",
            "Complesso"
        ]
    },
    {
        "_id": "143955",
        "name": "Sartori Amarone Asolo DOCG + Calici 75cl",
        "category": "Rosso",
        "region": "Veneto",
        "description": "Vino rosso italiano IGP avvolgente con uve Corvina, Rondinella e Molinara.",
        "price": "29.90",
        "features": [
            "Avvolgente",
            "Complesso"
        ]
    },
    {
        "_id": "147656",
        "name": "Casale Giglio Cabernet Sauvignon IGT 75cl",
        "category": "Rosso",
        "region": "Lazio",
        "description": "Vino rosso italiano IGT con uve Cabernet Sauvignon, caratterizzato da profumi intensi e complessi.",
        "price": "19.90",
        "features": [
            "Intenso",
            "Robusto"
        ]
    },
    {
        "_id": "151408",
        "name": "Mastroberardino Contrade di Legno Taurasi 75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Vino rosso italiano IGT elegante e strutturato con uve Aglianico.",
        "price": "35.90",
        "features": [
            "Elegante",
            "Robusto"
        ]
    },
    {
        "_id": "154896",
        "name": "San Salvatore Jungano 75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Vino rosso italiano IGT fruttato e armonico con uve Aglianico.",
        "price": "19.90",
        "features": [
            "Fruttato",
            "Robusto"
        ]
    },
    {
        "_id": "157534",
        "name": "De Liso Aglianico 75cl Expo 210pz",
        "category": "Rosso",
        "region": "Campania",
        "description": "Vino rosso italiano IGT di alta qualità con uve Aglianico.",
        "price": "417.00",
        "features": [
            "Elegante",
            "Robusto"
        ]
    },
    {
        "_id": "158381",
        "name": "Nicosia Passaluna Rosso 2x75cl",
        "category": "Rosso",
        "region": "Sicilia",
        "description": "Vino rosso italiano IGT con blend di uve autoctone, ricco e avvolgente.",
        "price": "29.90",
        "features": [
            "Fruttato",
            "Avvolgente"
        ]
    },
    {
        "_id": "760733",
        "name": "Vestini Campagnano Pallagrello Nero 75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Vino rosso italiano IGT con uve Pallagrello Nero, caratterizzato da note fruttate e speziate.",
        "price": "23.90",
        "features": [
            "Elegante",
            "Fruttato"
        ]
    },
    {
        "_id": "772155",
        "name": "Aglianico IGT Beneventano Alla Spinosa",
        "category": "Rosso",
        "region": "Campania",
        "description": "Vino rosso italiano IGT complesso e strutturato con uve Aglianico.",
        "price": "72.50",
        "features": [
            "Complesso",
            "Robusto"
        ]
    },
    {
        "_id": "772157",
        "name": "Puglia Primitivo IGP Spina",
        "category": "Rosso",
        "region": "Puglia",
        "description": "Vino rosso italiano IGP intenso e avvolgente con uve Primitivo.",
        "price": "89.00",
        "features": [
            "Intenso",
            "Fruttato"
        ]
    },
    {
        "_id": "772158",
        "name": "Terre di Chieti Rosso IGP",
        "category": "Rosso",
        "region": "Abruzzo",
        "description": "Vino rosso italiano IGP equilibrato con uve Montepulciano e Sangiovese.",
        "price": "73.00",
        "features": [
            "Armonico",
            "Robusto"
        ]
    },
    {
        "_id": "772159",
        "name": "Lambrusco Puglia IGP Spina",
        "category": "Rosso",
        "region": "Puglia",
        "description": "Vino rosso italiano IGP fresco e frizzante con uve Lambrusco.",
        "price": "72.00",
        "features": [
            "Fresco",
            "Fruttato"
        ]
    },
    {
        "_id": "773157",
        "name": "Kapnios Aglianico IGT 75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Vino rosso italiano IGT di alta qualità con uve Aglianico.",
        "price": "77.90",
        "features": [
            "Elegante",
            "Robusto"
        ]
    },
    {
        "_id": "CS00010",
        "name": "Aglianico Corleto IGP 75cl San Salvatore",
        "category": "Rosso",
        "region": "Campania",
        "description": "Vino rosso italiano IGP con uve Aglianico, caratterizzato da freschezza e persistenza.",
        "price": "19.00",
        "features": [
            "Fresco",
            "Persistente"
        ]
    },
    {
        "_id": "CS00011",
        "name": "Aglianico Gillo IGT Bio 75cl San Salvatore",
        "category": "Rosso",
        "region": "Campania",
        "description": "Vino rosso italiano IGT biologico con uve Aglianico, dal carattere intenso e armonico.",
        "price": "39.00",
        "features": [
            "Fresco",
            "Intenso"
        ]
    },
    {
        "_id": "CS00012",
        "name": "Aglianico Jungano IGT 75cl San Salvatore",
        "category": "Rosso",
        "region": "Campania",
        "description": "Vino rosso italiano IGT con uve Aglianico, dal profilo aromatico e persistente.",
        "price": "18.00",
        "features": [
            "Aromatico",
            "Persistente"
        ]
    },
    {
        "_id": "CS00034",
        "name": "Aglianico Naima IGT 75cl De Concilis",
        "category": "Rosso",
        "region": "Campania",
        "description": "Vino rosso italiano IGT con uve Aglianico, caratterizzato da profumi intensi e persistenti.",
        "price": "32.90",
        "features": [
            "Intenso",
            "Persistente"
        ]
    },
    {
        "_id": "CS00063",
        "name": "Aglianico Kleos IGT 75cl",
        "category": "Rosso",
        "region": "Campania",
        "description": "Vino rosso italiano IGT con uve Aglianico, dal carattere armonioso e strutturato.",
        "price": "18.90",
        "features": [
            "Armonico",
            "Strutturato"
        ]
    },
    {
        "_id": "CS00160",
        "name": "Ricasoli Brolio Riserva",
        "category": "Rosso",
        "region": "Toscana",
        "description": "Vino rosso italiano IGP Riserva con uve selezionate, dal gusto complesso e avvolgente.",
        "price": "19.90",
        "features": [
            "Complesso",
            "Avvolgente"
        ]
    },
    {
        "_id": "CS00171",
        "name": "Masi Ripasso Campofiorin lt.1",
        "category": "Rosso",
        "region": "Veneto",
        "description": "Vino rosso italiano IGT Ripasso, con un ricco e avvolgente bouquet aromatico.",
        "price": "27.90",
        "features": [
            "Elegante",
            "Avvolgente"
        ]
    },
    {
        "_id": "CS00822",
        "name": "Cantine Notaio La Firma DOC",
        "category": "Rosso",
        "region": "Basilicata",
        "description": "Vino rosso italiano DOC con uve selezionate, caratterizzato da profumi intensi e persistenti.",
        "price": "25.90",
        "features": [
            "Intenso",
            "Persistente"
        ]
    },
    {
        "_id": "CS02098",
        "name": "Vigna dei Russi Riserva Aglianico Cobellis",
        "category": "Rosso",
        "region": "Campania",
        "description": "Vino rosso italiano IGT Riserva con uve Aglianico, dal carattere ricco e complesso.",
        "price": "30.00",
        "features": [
            "Robusto",
            "Complesso"
        ]
    },
    {
        "_id": "CS02099",
        "name": "Vigna dei Russi DOP Aglianico Cobellis",
        "category": "Rosso",
        "region": "Campania",
        "description": "Vino rosso italiano DOP con uve Aglianico, caratterizzato da un gusto armonico e persistente.",
        "price": "25.00",
        "features": [
            "Armonico",
            "Persistente"
        ]
    },
    {
        "_id": "CS02452",
        "name": "Il Bruciato Bolgheri DOC 75cl Antinori",
        "category": "Rosso",
        "region": "Toscana",
        "description": "Vino rosso italiano DOC di Bolgheri, dal gusto avvolgente e armonico.",
        "price": "21.50",
        "features": [
            "Avvolgente",
            "Armonico"
        ]
    },
    {
        "_id": "CS02455",
        "name": "Fontanafredda",
        "category": "Rosso",
        "region": "Piemonte",
        "description": "Vino rosso italiano IGP, con caratteristiche fruttate e gusto equilibrato.",
        "price": "21.00",
        "features": [
            "Fruttato",
            "Armonico"
        ]
    },
    {
        "_id": "CS03840",
        "name": "Aglianico Jungano IGT 1,5L San Salvatore",
        "category": "Rosso",
        "region": "Campania",
        "description": "Vino rosso italiano IGT con uve Aglianico, in formato magnum, dal carattere intenso e complesso.",
        "price": "38.00",
        "features": [
            "Intenso",
            "Complesso"
        ]
    },
    {
        "_id": "CS05295",
        "name": "Masi Vino Brolo Oro IGT 75cl",
        "category": "Rosso",
        "region": "Veneto",
        "description": "Vino rosso italiano IGT con caratteristiche fruttate e speziate, dal gusto avvolgente.",
        "price": "21.90",
        "features": [
            "Fruttato",
            "Speziato"
        ]
    },
    {
        "_id": "127273",
        "name": "Veuve Clicquot Rich 75cl",
        "category": "Rosato",
        "region": "Francia",
        "description": "Vino rosato italiano IGP con un carattere fresco e fruttato, perfetto per le occasioni speciali.",
        "price": "49.90",
        "features": [
            "Fresco",
            "Fruttato"
        ]
    },
    {
        "_id": "127280",
        "name": "Donne Fittipaldi Bolgheri 75cl",
        "category": "Rosato",
        "region": "Toscana",
        "description": "Vino rosato italiano IGP con uve selezionate, caratterizzato da note floreali e fruttate.",
        "price": "20.90",
        "features": [
            "Floreale",
            "Fruttato"
        ]
    },
    {
        "_id": "127293",
        "name": "S. Marzano Prim. Mand. Ann.62 75cl",
        "category": "Rosato",
        "region": "Puglia",
        "description": "Vino rosato italiano IGP con un carattere elegante e raffinato, perfetto per le serate speciali.",
        "price": "34.90",
        "features": [
            "Elegante",
            "Elegante"
        ]
    },
    {
        "_id": "127295",
        "name": "S. Marzano Primitivo di Manduria 150cl",
        "category": "Rosato",
        "region": "Puglia",
        "description": "Vino rosato italiano IGP con intensi aromi di frutti rossi, corpo pieno e persistenza al palato.",
        "price": "70.00",
        "features": [
            "Fruttato",
            "Robusto"
        ]
    },
    {
        "_id": "127297",
        "name": "Frescobaldi Mormoreto 75cl",
        "category": "Rosato",
        "region": "Toscana",
        "description": "Vino rosato italiano IGP con caratteristiche eleganti, struttura complessa e finale persistente.",
        "price": "69.90",
        "features": [
            "Elegante",
            "Complesso"
        ]
    },
    {
        "_id": "127310",
        "name": "Villa Matilde Cecubo IGT 75cl",
        "category": "Rosato",
        "region": "Campania",
        "description": "Vino rosato italiano IGP con note fruttate e floreali, equilibrato e fresco al palato.",
        "price": "28.90",
        "features": [
            "Fruttato",
            "Floreale"
        ]
    },
    {
        "_id": "138196",
        "name": "San Salvatore Vetere IGP Paestum Rosato 75cl",
        "category": "Rosato",
        "region": "Campania",
        "description": "Vino rosato italiano IGP con un profilo fresco, fruttato e delicato, ideale per le giornate estive.",
        "price": "19.40",
        "features": [
            "Fresco",
            "Fruttato"
        ]
    },
    {
        "_id": "139734",
        "name": "Tenuta San Francesco Tramonti Rosato 75cl",
        "category": "Rosato",
        "region": "Campania",
        "description": "Vino rosato italiano IGP con un gusto armonioso e fresco, perfetto come aperitivo.",
        "price": "15.90",
        "features": [
            "Armonico",
            "Fresco"
        ]
    },
    {
        "_id": "152213",
        "name": "Marisa Cuomo Costa d'Amalfi Rosato 75cl",
        "category": "Rosato",
        "region": "Campania",
        "description": "Vino rosato italiano IGP con note di frutta fresca, piacevole freschezza e equilibrio al palato.",
        "price": "24.90",
        "features": [
            "Fruttato",
            "Armonico"
        ]
    },
    {
        "_id": "154895",
        "name": "San Salvatore Vetere 75cl",
        "category": "Rosato",
        "region": "Campania",
        "description": "Vino rosato italiano IGP con un profilo fresco e fruttato, ottimo per accompagnare piatti leggeri.",
        "price": "18.90",
        "features": [
            "Fresco",
            "Fruttato"
        ]
    },
    {
        "_id": "CS03837",
        "name": "Aglianico Rosato Vetere Paestum 1,5L San Salvatore",
        "category": "Rosato",
        "region": "Campania",
        "description": "Vino rosato italiano IGP in formato Magnum, con note vivaci e persistenti di frutta rossa.",
        "price": "36.50",
        "features": [
            "Fruttato",
            "Intenso"
        ]
    }
]

export default allWines;