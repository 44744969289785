const Choiches = {
    0: {
        "id": 0,
        "sommelier": {
            "question": "Che tipo di Vino stai cercando?"
        },
        "user": {
            "sentence": "Sto cercando un Vino ...",
            "input": {
                "category": ["Bianco", "Rosso", "Rosato", "Vino Estero", "Vino Liquoroso"]
            }
        }
    },
    1: {
        "id": 1,
        "sommelier": {
            "question": "Quale caratteristica ti interessa?"
        },
        "user": {
            "sentence": "che sia ...",
            "input": {
                "features": ["Fruttato","Elegante","Leggero","Floreale","Aromatico","Complesso","Minerale","Agrumato","Intenso","Speziato","Vivace","Fresco","Robusto","Strutturato","Armonico","Persistente","Avvolgente","Dolce","Potente"]
            }
        }
    }
}

export default Choiches