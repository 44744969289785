import './App.css'
import SmartSommelier from './SmartSommelier'
import { useWakeLock } from 'react-screen-wake-lock';
import { useEffect } from 'react';

function App() {  

  const { isSupported, released, request, release } = useWakeLock({
    onRequest: () => console.log('Screen Wake Lock: requested!'),
    onError: () => console.log('An error happened 💥'),
    onRelease: () => console.log('Screen Wake Lock: released!'),
  });

  useEffect(() => {
    request()
  }, [])

  return (
    <div className="App" style={ {backgroundImage: 'url("/imgs/ui_bg.png")'} }>
      <img src="/imgs/wine_glasses.gif" />
      <SmartSommelier />
    </div>
  );
}

export default App;
