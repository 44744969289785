import allWines from './Wines'
import choiches from './Choiches'
import UserInput from './UserInput'
import WineList from './WineList'
import React, {useState} from 'react'

const SmartSommelier = () => {

    const [selectedWine, setSelectedWine] = useState(-1)
    const [chosenCategory, setChosenCategory] = useState('')
    const [choiche, setChoiche] = useState(0)
    const [wines, setWines] = useState(allWines)

    function resetChoiche() {
        setSelectedWine(-1)
        setWines(allWines)
        setChoiche(0)
    }

    function filterWines(feature) { 
        setWines( allWines.filter(i => ( 
            i[ Object.keys( choiches[1].user.input ) ].indexOf(feature) !== -1 && i['category'] === chosenCategory )
        ))
        setChoiche(2)
    }

    function setUserCategory(category) {
        setChosenCategory(category)
    }

    function updateChoiche(choiche) {
        setChoiche(choiche)
    }

    return (
        <div className="smart-sommelier">
            <UserInput choiche={ choiche } setUserCategory={setUserCategory} setChoiche={updateChoiche} filterWines={filterWines} resetChoiche={resetChoiche}/>
            <WineList choiche={ choiche } wines={ wines } selectedWine={ selectedWine } setSelectedWine={ setSelectedWine }/>
        </div>
    )
}

export default SmartSommelier