import React, { useState } from 'react'
import choiches from './Choiches'
import FeatureByCategory from './FeaturesByCategory'

const UserInput = ( {choiche, setUserCategory, setChoiche, filterWines, resetChoiche} ) => {

    const [selCategory, setSelCategory] = useState('')
    const [selFeature, setSelFeature] = useState(-1)
    const [userSentence, setUserSentence] = useState('')

    function categorySelection(i, cat) {
        setChoiche(i)
        setUserCategory(cat)
        setUserSentence('Un vino ' + cat + ' ')
        setSelCategory(cat)
    }

    function featureSelection(i, index) {
      filterWines(i)
      setSelFeature(index)
    }

    function resetAllChoiches() {
      setUserSentence('')
      setSelCategory('')
      setSelFeature(-1)
      resetChoiche()
    }

    return (
        <div className='input'>

          <div className='input-ai'>
            <img src="/imgs/ai.png" className="ai-img" />
            <div className="ai-text">
              <h2>Ciao, ti aiuterò a scegliere il vino</h2>
              <h2>{ choiches[choiche] ? choiches[choiche].sommelier.question : choiches[1].sommelier.question }</h2>
            </div>
            {choiche > 1 && <img src="/imgs/reset.png" className="ai-reset" onClick={ resetAllChoiches }/> }
          </div>
          
          <div className='input-user'>
            <div className="user-text">
              <h2>{ choiches[choiche] ? userSentence + choiches[choiche].user.sentence : userSentence + choiches[1].user.sentence }</h2>
              <div className="user-buttons">
                { choiche == 0 && 
                  choiches[0].user.input.category.map((i, index) => (
                    <button key={index} onClick={() => categorySelection(1, i) }>{i}</button>
                  ))
                }
                {
                  choiche > 0 &&
                  FeatureByCategory[selCategory].map((i, index) => (
                    <button key={index} onClick={() => featureSelection(i, index)} className={selFeature === index ? 'sel-feature' : ''}>{i}</button>
                  ))
                }


                {/* choiche > 0 && 
                  choiches[1].user.input.features.map((i, index) => (
                    <button key={index} onClick={() => featureSelection(i, index)} className={selFeature === index ? 'sel-feature' : ''}>{i}</button>
                  ))
                  */}
              </div>
            </div>
            <img src="/imgs/user.png" className="user-img" onClick={() => window.location.reload(true)} />
          </div>
      </div>
    )
}

export default UserInput